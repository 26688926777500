import {Component} from '@angular/core';
import { AuthService } from 'app/core/services';

@Component({
  selector: 'sa-header-right-buttons',
  templateUrl: './sa-header-right-buttons.component.html',
  styleUrls: ['./sa-header-right-buttons.component.scss']
})
export class SaHeaderRightButtonsComponent {
  constructor(public authService:AuthService) {
    
  }

}

<header id="header">
  <div id="logo-group">
    <span>
      <img *ngIf="accountLogo" [src]="'data:image/png;base64,' + accountLogo" class="custom-logo-width-main"
        alt="Logo" />
    </span>
    <sa-collapse-menu></sa-collapse-menu>
  </div>
  <div class="pull-right">
    <ul id="mobile-profile-img" class="header-dropdown-list hidden-xs padding-5">
      <li class="">
        <a href="#" class="dropdown-toggle no-margin userdropdown" data-toggle="dropdown">
          <img src="assets/img/avatars/sunny.png" alt="John Doe" class="online" />
        </a>
        <ul class="dropdown-menu pull-right">
          <li>
            <a (click)="(null)" class="padding-10 padding-top-0 padding-bottom-0"><i class="fa fa-cog"></i>
              {{'Setting'|i18n}}
            </a>
          </li>
          <li class="divider"></li>
          <li>
            <a routerLink="/app-views/profile" class="padding-10 padding-top-0 padding-bottom-0">
              <i class="fa fa-user"></i>
              <u>P</u>rofile
            </a>
          </li>
          <li class="divider"></li>
          <li>
            <a (click)="(null)" class="padding-10 padding-top-0 padding-bottom-0" data-action="toggleShortcut">
              <i class="fa fa-arrow-down"></i> <u>S</u>hortcut
            </a>
          </li>
          <li class="divider"></li>
          <li>
            <a (click)="(null)" class="padding-10 padding-top-0 padding-bottom-0" data-action="launchFullscreen">
              <i class="fa fa-arrows-alt"></i> Full <u>S</u>creen
            </a>
          </li>
          <li class="divider"></li>
          <li>
            <a routerLink="/auth/login" class="padding-10 padding-top-5 padding-bottom-5" data-action="userLogout">
              <i class="fa fa-sign-out fa-lg"></i> <strong><u>L</u>ogout</strong>
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <div class="client-dropdown-container">
      <img style="cursor: pointer;" src="assets/img/faq.png" alt="faq" width="33" height="28" (click)="goToFaq()"
        title="Click to View Frequently Asked Questions" />
      <div class="dropdown client-options-dropdown" dropdown>
        <a class="dropdown-toggle d-flex" dropdownToggle>
          <div class="flex-column">
            <span class="user-username">{{authService.user?.username }}</span>
            <span *ngIf="authService.user && authService.user.roles && authService.user.roles.length > 0"
              class="user-role">{{authService.user?.roles[0] }}</span>
          </div>
          <i class="fa fa-angle-down dropdown-arrow"></i>
        </a>
        <ul style="min-width:100%;width: 101%;" *dropdownMenu class="dropdown-menu client">
          <li><a routerLink="/administration/account"><i class="fa fa-user"></i> {{ 'My Account' | i18n }}</a></li>
          <li>
            <sa-logout></sa-logout>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
<span class={{item.status}}>
  <a class="msg">
    <img src={{item.image}} alt="" class="air air-top-left margin-top-5" width="40"
         height="40"/>
    <span class="from">{{item.title | i18n}}</span>
    <span>{{item.time}}</span>
    <span class="subject">{{item.subject | i18n}}</span>
    <span class="msg-body">{{item.message | i18n}}</span>
  </a>
</span>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDataSource',
})
export class CustomDatasourcePipe implements PipeTransform {
  constructor() { }

  transform(dataSource: any, shouldReRunPipe: boolean): any {
    if (dataSource != null && shouldReRunPipe) {
      return JSON.parse(dataSource.replace(/\\/g, ""));
    }
  }
}


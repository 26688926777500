import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sa-button',
  templateUrl: './sa-button.component.html',
  styleUrls: ['./sa-button.component.scss']
})
export class SaButtonComponent {
  @Input() routerLink: string = ''; // Button router link
  @Input() icon: string | null = null; // Button icon (optional)
  @Input() color: string | null = "primary"; // Button color
  @Input() alignment: string = 'left'; // Options: left, right
  @Input() colorShade: string = 'dark'; // Options: dark, light
  @Input() size: string = 'md'; // Options: lg, md, sm
  @Input() isDisabled: boolean = false; // Options: true, false
  @Input() customClass: string | undefined = null; // Custom class that is defined in global css file
  @Input() hint: string = '';
  @Input() buttonType: string = '';
  @Input() popoverTitle: string = '';
  @Input() popoverBody: string = '';
  @Input() customStyles: object = {};
  @Input() id: string = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>(); // Click event of the button
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, HelperFunctionsService } from 'app/core/services';

@Component({
  selector: '[activitiesNotification]',
  templateUrl: './activities-notification.component.html',
  styleUrls: ['./activities-notification.component.css']
})
export class ActivitiesNotificationComponent implements OnInit {

  @Input() item: any;
  @Output() updateEvent = new EventEmitter<boolean>();
  @Output() markItem = new EventEmitter<number>();

  constructor(private apiService: ApiService
    , private helperFunctionsService: HelperFunctionsService
    , private router: Router) { }

  isRead: boolean = false;

  ngOnInit() { }

  setClasses() {
    let classes = {
      'fa fa-fw fa-2x': true,
      "fa fa-info-circle": true
    };
    return classes
  }

  goToReleaseNotes() {
    this.router.navigate(['/administration/release-notes'])
  }

  markAsRead() {
    const notificationService = this.apiService.updateNotificationIsRead(this.item.id)
      .subscribe(() => {
        this.updateEvent.next(true);
        notificationService.unsubscribe();
      }, error => {
        this.helperFunctionsService.displayNotificationErrorAlert(error);
        notificationService.unsubscribe();
      });
  }

  getIndicatorColor() {
    switch (this.item.notificationStatus) {
      // Pending
      case 1: {
        return "#ff9617";
      }
      // Success
      case 2: {
        return "#33c619";
      }
      // Fail
      case 3: {
        return "#ff3c3c";
      }
    }
  }
}

<ul class="header-dropdown-list hidden-xs ng-cloak">
  <li class="dropdown" dropdown>
    <sa-icon-button
      dropdownToggle
      title="User"
      icon="user"
      size="lg"
    ></sa-icon-button>

    <ul *dropdownMenu id="user-options-dropdown" class="dropdown-menu">
      <div
        style="
          display: flex;
          flex-direction: column;
          padding: 8px 9px;
          border-bottom: 1px solid #ececec;
        "
        class="flex-column"
      >
        <span class="user-username">{{
          authService.user?.username
        }}</span>
        <span
          *ngIf="
            authService.user &&
            authService.user.roles &&
            authService.user.roles.length > 0
          "
          class="user-role"
          >{{ authService.user?.roles[0] }}</span
        >
      </div>
      <li>
        <a class="user-options-link" routerLink="/administration/account"
          ><i class="las la-user-edit user-options-icon"></i>
          {{ "My Account" | i18n }}</a
        >
      </li>
      <li>
        <sa-full-screen> </sa-full-screen>
      </li>
      <li>
        <sa-logout></sa-logout>
      </li>
    </ul>
  </li>
</ul>

<style>
  .collapse-btn {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    border-color: rgb(228, 226, 226) !important;
    background: #fbfbfb !important;
    color: rgb(80, 80, 80) !important;
  }
</style>
<div id="hide-menu" class="btn-header pull-right">
  <span> <a id="auth-header-menu-icon" class="collapse-btn" (click)="onToggle()" title="{{'Collapse Menu'|i18n}}"><i
        class="fa fa-reorder"></i></a> </span>
</div>
<sa-icon-button icon="exclamation-triangle" [title]="'Health Check - '+healthCheck?.totalIndicatorsStateName"
  id="health-check" size="lg" [color]="healthCheck?.totalIndicatorsStateColor" (onClick)="onToggle()">
</sa-icon-button>

<div id="health-check-dropdown" class="ajax-dropdown" #dropdown>
  <span style="color: #292929;height: 32px;
    display: inline-block;margin-left: 5px;" class="hc-popup-title">{{'Health Check'}}
    <span style=" display: inline-block;
    margin: 0 5px 0 2px;" class="hc-popup-title"> -
    </span>
  </span>
  <span class="hc-popup-title"
    [style.color]="healthCheck?.totalIndicatorsStateColor">{{healthCheck?.totalIndicatorsStateName|i18n}}
  </span>
  <span type="button" (click)="navigateToHealthCheckAnalysis()" class="goto-analysis">
    {{ "Go to Analysis" | i18n }} <i class="fa fa-angle-double-right"></i>
  </span>

  <div *ngIf="healthCheck" class="ajax-notifications custom-scroll">
    <ul class="notification-body">
      <li *ngFor="let healthCheckIndicator of healthCheck?.healthCheckIndicators">
        <span class="padding-10 d-flex flex-column">
          <span class="d-flex">
            <em class="badge padding-5 no-border-radius bg-color-transparent pull-left margin-right-5">
              <i class="fa fa-circle" [style.color]="
                  healthCheckIndicator.activeIndicator == true
                    ? healthCheckIndicator.indicatorStateColor
                    : healthCheckIndicator.activeIndicatorColor">
              </i>
            </em>
            <span [style.color]="healthCheckIndicator.activeIndicatorColor">
              {{ healthCheckIndicator.indicatorTypeName | i18n }}
            </span>
          </span>
          <span *ngIf="healthCheckIndicator.activeIndicator == true" class="text-muted">
            <span style="font-size: 11px; margin-left: 26px">{{ "Last Checked on" | i18n }}
              {{ healthCheckIndicator.checkDate | moment : "DD/MM/YYYY HH:mm" }}
            </span>
          </span>
        </span>
      </li>
    </ul>
  </div>

  <!-- end notification content -->

  <!-- footer: refresh area -->
  <div class="last-update-wrapper">
    <span class="last-update-text"><i style="margin-right: 3px;font-size: 1.1em;" class="fa fa-clock-o"
        aria-hidden="true"></i>
      {{ 'Last Update:' | i18n }} {{lastRefresh | moment:"h:mm A"}}</span>
    <div (click)="update()" title="Refresh notifications" class="refresh-nt">
      <i class="fa fa-refresh" *ngIf="!loading"></i>
      <i style="margin-right: 7px;font-size: .9em;" class="fa fa-refresh fa-spin" *ngIf="loading"></i>
      <span *ngIf="loading">{{ 'Loading' | i18n}}...</span>
    </div>
  </div>
  <!-- end footer -->
</div>
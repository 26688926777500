import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SmartadminFormsLiteModule } from "./forms/smartadmin-forms-lite.module";
import { SmartadminLayoutModule } from "./layout";
import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";

import { PipesModule } from "../core/pipes/pipes.module";
import { I18nModule } from "./i18n/i18n.module";
import { UtilsModule } from "./utils/utils.module";

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Ng5SliderModule } from 'ng5-slider';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEditorModule } from 'ngx-editor';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrderModule } from 'ngx-order-pipe';
import { DirectivesModule } from "./directives/directives.module";
import { InlineGraphsModule } from "./graphs/inline/inline-graphs.module";
import { StatsModule } from "./stats/stats.module";
import { UiComponentsModule } from "./ui-components/ui-components.module";
import { SmartProgressbarModule } from "./ui/smart-progressbar/smart-progressbar.module";
import { BootstrapModule } from "./bootstrap.module";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDiagramModule,
  DxDropDownBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTemplateModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxValidatorModule
} from "devextreme-angular";
import { AvatarModule } from 'ngx-avatars';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SmartadminLayoutModule,
    BootstrapModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxEditorModule.forRoot({
      locals: {
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        underline: 'Underline',
        strike: 'Strike',
        blockquote: 'Blockquote',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',
        horizontal_rule: 'Horizontal rule',
        format_clear: 'Clear Formatting',
        insertLink: 'Insert Link',
        removeLink: 'Remove Link',
        insertImage: 'Insert Image',
        indent: 'Increase Indent',
        outdent: 'Decrease Indent',
        superscript: 'Superscript',
        subscript: 'Subscript',
        undo: 'Undo',
        redo: 'Redo',
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
        enterValidUrl: 'Please enter a valid URL',
      },
    }),
    TagInputModule,
    UiComponentsModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxChartsModule,
    I18nModule,
    AvatarModule,
    DxDiagramModule,
    DxPopupModule,
    DxTemplateModule,
    DxDataGridModule,
    DxTagBoxModule,
    DxValidatorModule,
    DxTabPanelModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxTreeViewModule,
    DxTreeListModule,
    DxTabsModule,
    DxButtonModule    
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SmartadminLayoutModule,
    BootstrapModule,
    I18nModule,
    UtilsModule,
    PipesModule,
    SmartadminFormsLiteModule,
    SmartProgressbarModule,
    InlineGraphsModule,
    SmartadminWidgetsModule,
    StatsModule,
    OrderModule,
    FilterPipeModule,
    NgMultiSelectDropDownModule,
    TagInputModule,
    Ng5SliderModule,
    NgxEditorModule,
    UiComponentsModule,
    DirectivesModule,
    NgxChartsModule,
    ColorPickerModule,
    DxDiagramModule,
    DxPopupModule,
    DxTemplateModule,
    DxTagBoxModule,
    DxDataGridModule,
    DxValidatorModule,
    DxDropDownBoxModule,
    DxTabPanelModule,
    DxTreeViewModule,
    DxRadioGroupModule,
    DxTreeListModule,
    DxTabsModule,
    DxDateBoxModule
  ]
})
export class SharedModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService, HelperFunctionsService, AuthService } from 'app/core/services';
import { Subscription, forkJoin } from "rxjs";

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {

  appVersion: any;
  developedBy: any;
  solutionName: any;
  displayCopyrights: any;
  timeZones: any[] = [];
  dbName: any;
  isSystemAdmin: boolean = false;
  isAdminSubscription: Subscription;
  apiSubscription: Subscription;

  constructor(
    private helperFunctionsService: HelperFunctionsService,
    private apiService: ApiService,
    public authService: AuthService) { }

  ngOnInit() {
    this.isAdminSubscription = this.authService.isAdmin$
      .subscribe(isSystemAdmin => this.isSystemAdmin = isSystemAdmin);

    this.fetchData();
  }

  ngOnDestroy() {
    if (this.isAdminSubscription) this.isAdminSubscription.unsubscribe();
    if (this.apiSubscription) this.apiSubscription.unsubscribe();
  }

  fetchData() {
    forkJoin({
      timeZones: this.apiService.getTimeZones(),
      dbName: this.apiService.getDbName(),
      guestAccount: this.apiService.getGuestAccount(),
      appVersion: this.apiService.getAppVersion()
    }).subscribe(data => {
      this.timeZones = data.timeZones;
      this.dbName = data.dbName.dbName;
      this.developedBy = data.guestAccount.developedBy;
      this.solutionName = data.guestAccount.name;
      this.displayCopyrights = data.guestAccount.displayCopyrights;
      this.appVersion = data.appVersion.appVersion;
    }, error => {
      this.helperFunctionsService.displayNotificationErrorAlert(error);
    })
  }

  onUserTimezoneChange(e: any) {
    this.apiSubscription = this.apiService.updateUserTimezone(e.itemData.id)
      .subscribe({
        next: () => this.authService.syncUser()
      });
  }
}

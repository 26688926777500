<div class="header-right-buttons">
  <div class="header-right-buttons-inner-wrapper">
    <sa-icon-button title="Click to view Frequently Asked Questions" routerLink="/main/faq" icon="question"
      size="lg"></sa-icon-button>

    <sa-health-check></sa-health-check>

    <sa-activities> </sa-activities>

    <sa-language-selector></sa-language-selector>

    <sa-user-options></sa-user-options>
  </div>
</div>
<div *ngIf="serviceName == 'WorldCheck'">
  <sa-alert type="primary" icon="info" text="Screening Results From Refinitiv World-Check"></sa-alert>
</div>
<div *ngIf="serviceName == 'Rdc'">
  <h5> {{'Screening Results by Rdc'|i18n}}</h5>
</div>
<div *ngIf="serviceName == 'Acuris' || serviceName == 'RegTek+ Default Aggregator' ">
  <!--ACURIS == RegTek+ Default Aggregator-->
  <sa-alert type="primary" icon="info" text="Screening Results by RegTek+ Default Aggregator"></sa-alert>
</div>
<div *ngIf="serviceName == 'Bridger'">
  <sa-alert type="primary" icon="info" text="Screening Results by Bridger"></sa-alert>
</div>
<div *ngIf="serviceName == 'DowJones'">
  <sa-alert type="primary" icon="info" text="Screening Results by DowJones"></sa-alert>
</div>
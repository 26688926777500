import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
  name: 'objectToArray'
})
export class ObjectToArrayPipe  implements PipeTransform {

  transform(value: any): any[] {
    if (!value) return;
    return Object.entries(value);
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SaSearchBoxComponent} from "./sa-search-box.component";

// const UI = [
// ];

@NgModule({
  declarations: [
    SaSearchBoxComponent
  ],
  imports: [
    CommonModule,
    // ...UI
  ],
  exports: [
    SaSearchBoxComponent
  ]
})
export class SaSearchBoxModule {
}

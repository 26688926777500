<div [ngStyle]="{'background-color': item.isRead?'white':'rgb(255 237 177 / 23%)'}" class="item-wrapper">
  <div class="item-wrapper-inner">
    <div class="item-message" [innerHTML]="item.message"></div>
  </div>
  <div class="c-flex-wrapper">
    <span class="item-created-at">
      <i style="margin-right:3px;" class="fa fa-calendar" aria-hidden="true"></i>
      {{(item.modifiedDate||item.createdDate)| moment:"MMM DD, YYYY"}}
    </span>
    <span (click)="markItem.emit(item.id)" class="mark-btn">
      <span *ngIf="!item.isRead" class="c-check">✓</span>
      <i *ngIf="item.isRead" class="fa fa-eye-slash" aria-hidden="true"></i>
      <span style="margin-left:3px;display:inline-block;"></span>
      {{item.isRead == true? ' Mark as unread' : 'Mark as read' | i18n}}
    </span>
  </div>
  <span (click)="goToReleaseNotes()" class="mark-btn view"><i class="fa fa-eye"></i> View more</span>
</div>
import { Component, OnInit, ElementRef, OnDestroy } from "@angular/core";
import { Idle, EventTargetInterruptSource } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Router } from "@angular/router";
import { environment } from 'environments/environment';
import { NotificationService, AuthService } from "app/core/services";
import { I18nService } from "app/shared/i18n/i18n.service";
import { routerTransition } from "app/shared/utils/animations";


@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  animations: [routerTransition]
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  //idleState = 'Not started.';
  timedOut = false;

  onIdleEndService: any;
  onTimeoutService: any;
  onIdleStartService: any;
  onTimeoutWarningService: any;

  constructor(private element: ElementRef
    , private idle: Idle
    , private keepalive: Keepalive
    , private notificationService: NotificationService
    , public authService: AuthService
    , private router: Router
    , private i18nService: I18nService) { }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  showIdleNotice() {
    this.notificationService.smartMessageBox({
      title: `<i class='fa fa-shield txt-color-orange'></i> <span class='txt-color-orange'><strong>${this.i18nService.getTranslation('Session')}</strong></span> ${this.i18nService.getTranslation('Timeout')}!`,
      content: this.i18nService.getTranslation("For security reason, your session is being timed out. Click OK to login again."),
      buttons: '[Ok]'
    }, (ButtonPressed) => {
      if (ButtonPressed === "Ok") {
        this.authService.logout();
        this.router.navigate(["/auth/login"]);
      }
    });
  }

  ngOnInit() {
    // sets an idle timeout of 60 minutes.
    let idleTimeout = environment.IdleTimeout;
    if (idleTimeout == null) {
      idleTimeout = 3600;
    }

    this.idle.setIdle(idleTimeout);
    console.log("idle Time (6 is equal to 1 minute): " + idleTimeout);

    // sets a timeout period of 1 second.
    this.idle.setTimeout(1);
    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);

    this.onIdleEndService = this.idle.onIdleEnd.subscribe(() => {
      //this.idleState = 'NO_LONGER_IDLE';
      console.log("NO LONGER IDLE");
    });

    this.onTimeoutService = this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;

      console.log('timeout');
      this.idle.stop();
      //prevent init multiple time
      this.idle.onTimeout.observers.length = 0;
      this.idle.onIdleStart.observers.length = 0;
      this.idle.onIdleEnd.observers.length = 0;
      this.showIdleNotice();
    });

    this.onIdleStartService = this.idle.onIdleStart.subscribe(() => {
      console.log("idle starting .............");
    });

    this.onTimeoutWarningService = this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      console.log("idle in progress");
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    this.reset();
  }

  ngOnDestroy() {
    this.onIdleEndService.unsubscribe();
    this.onTimeoutService.unsubscribe();
    this.onIdleStartService.unsubscribe();
    this.onTimeoutWarningService.unsubscribe();
  }

  getState(outlet) {
    if (outlet && !outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}

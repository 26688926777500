import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-record-info',
  templateUrl: './record-info.component.html',
  styleUrls: ['./record-info.component.css']
})
export class RecordInfoComponent {
  @Input() recordInfo;
}

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({

  selector: 'sa-information',
  template: `
      <sa-widgets-grid>
            <!-- row -->
            <div class="row">
              <!-- NEW WIDGET START -->
              <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 sortable-grid">
                <!-- Widget ID (each widget will need unique ID)-->
                <div sa-widget [editbutton]="false" [deletebutton]="false" [fullscreenbutton]="false" [custombutton]="true"
                  [collapsed]="collapsed" [colorbutton]="false" [sortable]="false">
                  <header>
                    <div class="widget-toolbar smart-form pull-left searchBar">
                      <label class="input">  <i class="fa fa-info-circle"></i> {{' Information'|i18n}}
                      </label>
                    </div>
                  </header>
                    <!-- widget content -->
                    <div class="widget-body">
                      <article *ngFor="let item of items">
                        <p> {{item}} </p>
                      </article>
                      <article *ngIf="html && html.length > 0" [innerHTML]="safeHtml">
                      </article>
                    </div>
                    <!-- end widget content -->
                  </div>
              </article>
            </div>
          </sa-widgets-grid>
  `,
})
export class InformationComponent implements OnInit {

  @Input() public icon: string;
  @Input() public items: Array<string>;
  @Input() public html: string;
  @Input() public collapsed: boolean = true;

  public safeHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() { }

  ngOnChanges() {
    if (this.html && this.html.length > 0) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as actions from './auth.actions';

import { tap, filter, switchMap, map } from 'rxjs/operators';

// import { fireApp, fireAuth } from '../../firebase';
import { AuthState } from './auth.reducer';
import { Store } from '@ngrx/store';
import { AuthTokenService } from '../../services/auth-token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { RouteStructure } from 'app/shared/classes/routeStructure';

// const auth = fireApp.auth();

@Injectable()
export class AuthEffects {
  
  public routeStructure = new RouteStructure();


  redirectUrl: string = "/dashboard";
  loginUrl: string = "/login";

  @Effect({ dispatch: false })
  login$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginAction),
    tap((data: any) => {})
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LogoutAction),
    tap((data: any) => {
      this.routeStructure.savePreviousPage(this.router);
      /*
      Calling this logout method from the licence-expired screen navigates the user back to the login page.
      The user is, therefore, not able to update the licence. Pass true to prevent the redirection.
      The default action is to take the user to the login page after logout() 
       */
      if (data.redirectToLogin === true){
        this.router.navigate(["/auth/login"]);  
      }
      // auth.signOut();
    })
  );

  @Effect({ dispatch: false })
  redirectToDashboard$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.DashboardRedirect),
    tap((data: any) => {
      this.router.navigate(["/main/dashboard"]);
      // auth.signOut();
    })
  );

  @Effect({ dispatch: false })
  signup$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.SignupAction),
    tap((data: any) => {
      // auth
      //   .createUserWithEmailAndPassword(
      //     data.payload.username,
      //     data.payload.password
      //   )
      //   .catch(this.dispatchError);
    })
  );

  @Effect({ dispatch: false })
  googleSign$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.GoogleSign),
    tap((data: any) => {
      // auth
      //   .signInWithPopup(new fireAuth.GoogleAuthProvider())
      //   .catch(this.dispatchError);
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.LoginRedirect),
    tap((data: any) => {
      this.redirectUrl = data.payload || "";
      this.router.navigate([this.loginUrl]);
    })
  );

  @Effect({ dispatch: false })
  authRedirect$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthTokenPayload),
    filter((_) => this.router.url === this.loginUrl),
    tap((data: any) => {
      this.router.navigate([this.redirectUrl]);
    })
  );

  @Effect()
  authUser$ = this.actions$.pipe(
    ofType(actions.AuthActionTypes.AuthUserChange),
    // switchMap((data: any) => data.payload.getIdToken()),
    tap((_) => (this.authToken.token = _)),
    map((_) => this.authToken.readPayload(_)),

    map((_) => new actions.AuthTokenPayload(_))
  );

  dispatchError = (err) => {
    this.store.dispatch(
      new actions.AuthFailure({
        code: err.code,
        message: err.message,
      })
    );
  };

  constructor(
    private actions$: Actions,
    private store: Store<AuthState>,
    private authToken: AuthTokenService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticeComponent } from './notice/notice.component';
import { CustomChatComponent } from './custom-chat/custom-chat.component';
import { SavedStatusComponent } from './saved-status/saved-status.component';
import { FormsModule } from '@angular/forms';
import { RecordInfoComponent } from './record-info/record-info.component';
import { I18nModule } from '../i18n/i18n.module';
import { ScreeningResultsProviderComponent } from './screening-results-provider/screening-results-provider/screening-results-provider.component';
import { SaAlertModule } from 'app/templates/sa-alert/sa-alert.module';
import { SaButtonModule } from 'app/templates/sa-button/sa-button.module';

const UI = [
  SaAlertModule,
  SaButtonModule
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    ...UI
  ],
  declarations: [NoticeComponent, SavedStatusComponent, CustomChatComponent, RecordInfoComponent, ScreeningResultsProviderComponent],
  exports: [NoticeComponent, SavedStatusComponent, CustomChatComponent, RecordInfoComponent, ScreeningResultsProviderComponent],
})
export class UiComponentsModule { }

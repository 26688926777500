import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {PopoverModule} from "ngx-bootstrap/popover";
import { I18nModule } from "../../i18n/i18n.module";
import { UserModule } from "../../user/user.module";
import { BigBreadcrumbsComponent } from "./big-breadcrumbs.component";
import { InformationComponent } from "./information.component";
import { MinifyMenuComponent } from "./minify-menu.component";
import { NavigationComponent } from "./navigation.component";
import { PipesModule } from "app/core/pipes/pipes.module";
import { SmartadminWidgetsModule } from "app/shared/widgets/smartadmin-widgets.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    UserModule,
    PipesModule,
    PopoverModule.forRoot(),
    SmartadminWidgetsModule
  ],
  declarations: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    NavigationComponent,
    InformationComponent
  ],
  exports: [
    BigBreadcrumbsComponent,
    MinifyMenuComponent,
    NavigationComponent,
    InformationComponent
  ]
})
export class NavigationModule { }

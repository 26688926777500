import { Pipe, PipeTransform } from '@angular/core';
import { HelperFunctionsService } from 'app/core/services';
import { I18nService } from '../../shared/i18n/i18n.service';

@Pipe({
  name: 'dynamicPopover',
})
export class DynamicPopoverPipe implements PipeTransform {
  constructor(private helperFunctions: HelperFunctionsService, public i18nService: I18nService) { }
  /**
    * The pipe already uses the i18n translation.
    */
  transform(instructionFlag: string, instructionContainer: 'title' | 'body'): string {
    var word = this.helperFunctions.getInstructions(instructionFlag, instructionContainer, true);

    return word != "" ? this.i18nService.getTranslation(word) : word;
  }
}


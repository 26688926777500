<div class="panel panel-default">
  <div class="panel-body status">
    <div *ngIf="showHeader" class="who clearfix">
      <h4>{{'Internal Notes'|i18n}}</h4>
    </div>
    <div #notesbody class="chat-body custom-scroll">
      <ul class="custom-note-list">
        <li #notesLoop *ngFor="let note of internalNotes;" class="message custom-message"
          [class.custom-my-note]="note.isViewing" [class.custom-partner-note]="!note.isViewing">
          <div class="message-text">
            <time class="custom-time">
              {{note.date}}
            </time>
            <a style="pointer-events: none; cursor: default;"
              [style.color]="note.isViewing ? '#0c4d8d' : '#'+intToRGB(hashCode(note.userName))" class="username">
              {{note.userName}}
            </a>{{note.message}}
            <i *ngIf="note.isViewing && (this.hasDeletePermissions && !isViewingHistory)" data-test="deleteInternalNote"
              (click)="deleteInternalNote(note.id)" title="{{'Delete note'|i18n}}" class="fa fa-trash delete-note"></i>
          </div>
        </li>
      </ul>
    </div>

    <div class="chat-footer">
      <!-- CHAT TEXTAREA -->
      <div class="textarea-div">
        <div class="typearea">
          <textarea [disabled]="!this.hasCreatePermissions || isViewingHistory" placeholder="{{placeholder|i18n}}"
            [(ngModel)]="noteText" class="custom-scroll" name="chat-input"></textarea>
        </div>
      </div>

      <!-- CHAT REPLY/SEND -->
      <span class="textarea-controls">
        <div class="widget-toolbar pull-left" *ngIf="isChainalysisActive">
          <i class="fa fa-asterisk"></i> {{'Chainalysis Alert Status'|i18n}}:
          <label class="select">
            <select [(ngModel)]="this.selectedChainalysisAlertReviewStatus" class="form-control"
              style="margin-top:1px; height: 30px;" name="chainalysisAlertStatus">
              <option value="{{chainalysisAlertStatus.value}}"
                *ngFor="let chainalysisAlertStatus of chainalysisAlertReviewStatusEnum;">
                {{chainalysisAlertStatus.description}}</option>
            </select> <i></i>
          </label>
        </div>
        <button *ngIf="this.hasCreatePermissions && !isViewingHistory" (click)="addMessage()" data-test="saveChatText"
          class="btn btn-sm btn-primary pull-right">
          {{'Add Note'|i18n}}
        </button>
      </span>
    </div>
  </div>
</div>
<div class="record-info">
  <sa-alert *ngIf="recordInfo?.dateCreated" text="{{ 'Created Date' | i18n }}: {{ recordInfo?.dateCreated }}"
    icon="calendar-check" type="warning" size="small">
  </sa-alert>
  <sa-alert *ngIf="recordInfo?.createdByName" text="{{ 'Created By' | i18n }}: {{ recordInfo?.createdByName }}"
    icon="pencil-alt" type="warning" size="small">
  </sa-alert>
  <sa-alert *ngIf="recordInfo?.dateModified" text="{{ 'Modified Date' | i18n }}: {{ recordInfo?.dateModified  }}"
    icon="calendar-check" type="warning" size="small">
  </sa-alert>
  <sa-alert *ngIf="recordInfo?.modifiedByName" text="{{ 'Modified By' | i18n }}: {{ recordInfo?.modifiedByName }}"
    icon="edit" type="warning" size="small">
  </sa-alert>
</div>
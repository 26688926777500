// search.directive.ts

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appSearch]'
})
export class SearchDirective implements OnChanges {
  @Input() searchTerm: string;
  @Input() clientMenu: boolean = false;

  constructor(private el: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      const term = this.searchTerm ? this.searchTerm.toLowerCase() : '';
      if (term.trim() === '' || !term.trim()) {
        this.showAllNavigationItems();
      } else {
        this.searchNavigation(term);
      }
    }
  }

  private searchNavigation(term: string): void {
    const elements = this.el.nativeElement.querySelectorAll('.main-menu>li');

    elements.forEach((element: HTMLElement) => {
      const textContent = element.innerText.toLowerCase();

      if (textContent.includes(term) || !term) {
        element.classList.remove('custom-hide');
        element.classList.add('custom-show');
      }
      else {
        element.classList.remove('custom-show');
        element.classList.add('custom-hide');
      }
    });
  }

  private showAllNavigationItems(): void {
    const elements = this.el.nativeElement.querySelectorAll('.main-menu>li');

    elements.forEach((element: HTMLElement) => {
      element.classList.remove('custom-hide');
      element.classList.add('custom-show');
    });
  }
}

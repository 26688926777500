import { Component, OnInit } from '@angular/core';
import config from "devextreme/core/config";
import DataGrid from "devextreme/ui/data_grid";
import drop_down_box from "devextreme/ui/drop_down_box";
import { AppService } from './app.service';
import { ApiService, AuthService } from './core/services';
import { I18nService } from './shared/i18n/i18n.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sa';
  solutionName: any;
  favicon: any;
  privacyPolicy: any;
  leftMainMenuMinimized: boolean = false;
  hideGlobalTemplate: boolean = false;

  dropDownDefaultOptions: any = {
    device: { deviceType: "desktop" },
    options: {
      stylingMode: "outlined",
      height: 34
    }
  };

  dataGridDefaultOptions: any = {
    device: { deviceType: "desktop" },
    options: {
      stylingMode: "outlined",
      columnChooser: {
        allowSearch: true,
        height: 350,
        width: 300,
        mode: "select"
      }
    }
  };

  constructor(private apiService: ApiService,
    public i18nService: I18nService,
    private _appService: AppService,
    public authService: AuthService,
    private router: Router) {
    this.initializeDefaultDxOptions();
    
    this.authService.load();

    if(this.authService.isLoggedIn) {
      this._appService.minimizeMainMenu();
      this._appService.showGlobalTemplate();
    }
    let isToggleOpen = localStorage.getItem('client-tree-structure-visible');
    localStorage.setItem('client-tree-structure-visible', isToggleOpen == null || isToggleOpen == undefined || isToggleOpen == 'true' ? 'true' : 'false');
  }

  ngOnInit() {
    this._appService.getMainMenuState$().subscribe(state => {
      this.leftMainMenuMinimized = state;
    });

    this._appService.getGlobalTemplateState$().subscribe(state => {
      this.hideGlobalTemplate = state;
    });

    this.getGuestAccount();
  }

  initializeDefaultDxOptions() {
    DataGrid.defaultOptions(this.dataGridDefaultOptions);
    drop_down_box.defaultOptions(this.dropDownDefaultOptions);
    config({
      editorStylingMode: "outlined"
    });
  }

  getGuestAccount() {
    const guestService = this.apiService.getGuestAccount()
      .subscribe(
        data => {
          this.solutionName = data.name;
          this.favicon = data.favicon;
          this.privacyPolicy = data.privacyPolicyLink;
          $("#pageTitle").text(this.solutionName);
          $("#faviconId").attr("href", `data:image/png;base64,${this.favicon}`);
          guestService.unsubscribe();
        },
        () => {
          $("#pageTitle").text("RegTek");
          guestService.unsubscribe();
        });
  }

  onPageContentScroll(event: any): void {
    this._appService.setScrollPosition(event.target.scrollTop);
  }
}

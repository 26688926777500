import {Component, Input} from '@angular/core';

@Component({
  selector: 'sa-alert',
  templateUrl: './sa-alert.component.html',
  styleUrls: ['./sa-alert.component.scss']
})
export class SaAlertComponent {
  @Input() type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = "primary"; // Type of the alert
  @Input() icon: string = ''; // Icon of alert
  @Input() text: string = ''; // Text of alert. Can also accept HTML
  @Input() size: string = 'medium';
  @Input() heading: string = '';
}

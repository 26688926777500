import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService, AuthService, HelperFunctionsService } from 'app/core/services';
import { JoyrideService } from 'ngx-joyride';

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  accountLogo: any;
  searchMobileActive = false;

  constructor(private router: Router
    , public authService: AuthService
    , private apiService: ApiService
    , private helperFunctionsService: HelperFunctionsService) {
  }

  ngOnInit() {
    this.getGuestAccountLogo();
  }

  getGuestAccountLogo() {
    this.apiService.getUserAdministrationAccountLogo().subscribe((data => this.accountLogo = data.logo),
      error => this.helperFunctionsService.displayNotificationErrorAlert(error))
  }

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);
  }

  goToFaq() {
    this.router.navigate(['/main/faq']);
  }
}

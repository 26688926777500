<ul *ngIf="currentLanguage" class="header-dropdown-list hidden-xs ng-cloak">
  <li class="dropdown" dropdown>
    <sa-icon-button dropdownToggle *ngIf="currentLanguage" icon="language" title="Language" size="lg"></sa-icon-button>

    <ul *dropdownMenu id="translate-language-dropdown" class="dropdown-menu">
      <li [class.active]="language == currentLanguage" *ngFor="let language of languages">
        <a (click)="setLanguage(language)" class="dropdown-item"><img style="position: relative;right: 5px;"
            src="assets/img/blank.gif" class="flag flag-{{ language.key }}" alt="{{ language.alt }}" />
          {{ language.title }}</a>
      </li>
    </ul>
  </li>
</ul>
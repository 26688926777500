

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { I18nModule } from '../i18n/i18n.module';
import { LoginInfoComponent } from "./login-info/login-info.component";
import { LogoutComponent } from "./logout/logout.component";

@NgModule({
  imports: 
  [
      CommonModule
     ,I18nModule
  ],
  declarations: [LoginInfoComponent, LogoutComponent],
  exports: [LoginInfoComponent, LogoutComponent]
})
export class UserModule{}

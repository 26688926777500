<ngx-ui-loader></ngx-ui-loader>
<sa-main-left-menu *ngIf="!hideGlobalTemplate"></sa-main-left-menu>
<div class="main-area" (scroll)="onPageContentScroll($event)"
  [ngClass]="{'minimized-menu': leftMainMenuMinimized, 'hide-global-template': hideGlobalTemplate}">
  <section class="header-right-area" *ngIf="!hideGlobalTemplate">
  </section>
  <section class="page-area">
    <div ngxUiLoaderBlurred>
      <router-outlet></router-outlet>
    </div>
  </section>
</div>
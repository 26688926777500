import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ApiService, HelperFunctionsService } from 'app/core/services';
import { Subscription } from 'rxjs';
import { ActivitiesService } from "./activities.service";

declare var $: any;

@Component({
  selector: 'sa-activities',
  templateUrl: './activities.component.html',
  providers: [ActivitiesService],
})
export class ActivitiesComponent implements OnInit, OnDestroy {

  apiSubscription: Subscription;

  count: number;
  lastUpdate: any;
  active: boolean;
  activities: any;
  currentActivity: any;
  loading: boolean;

  private documentSub: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private activitiesService: ActivitiesService,
    private apiService: ApiService,
    private helperFunctionsService: HelperFunctionsService
  ) {
    this.active = false;
    this.loading = false;
    this.activities = [];
    this.count = 0;
    this.lastUpdate = new Date();
  }

  ngOnInit() {
    this.fetchData();
  }

  ngOnDestroy() {
    this.documentUnsub();
    this.apiSubscription.unsubscribe();
  }

  fetchData() {
    this.apiSubscription = this.apiService.getNotifications()
      .subscribe({
        next: (data) => {
          this.activities = data;
          this.currentActivity = data[0];
        },
        error: (error) => this.helperFunctionsService.displayNotificationErrorAlert(error),
        complete: () => {
          this.activities[0].data.forEach(item => {
            item.isRead == false ? this.count++ : '';
            item.createdDate = item.createdDate.substring(0, 10);
          });
        }
      })
  }

  setActivity(activity) {
    this.currentActivity = activity;
  }

  getActivityTitle(activity) {
    const unreadNotifications = activity.data.filter(x => !x.isRead).length;

    if (unreadNotifications == 0) {
      return "Notifications"
    }
    else {
      return `Notifications(${unreadNotifications})`;
    }
  }

  markAllAsRead() {
    this.loading = true;
    this.apiSubscription = this.apiService.updateAllNotificationsIsRead(true)
      .subscribe(() => {
        this.loading = false;
        this.update();
      }, error => {
        this.loading = false;
        this.helperFunctionsService.displayNotificationErrorAlert(error);
      })
  }

  markAllAsUnread() {
    this.loading = true;
    this.apiSubscription = this.apiService.updateAllNotificationsIsRead(false)
      .subscribe(() => {
        this.loading = false;
        this.update();
      }, error => {
        this.loading = false;
        this.helperFunctionsService.displayNotificationErrorAlert(error);
      })
  }

  markSingleAsRead(itemId) {
    this.loading = true;
    this.apiSubscription = this.apiService.updateNotificationIsRead(itemId)
      .subscribe(() => {
        this.loading = false;
        this.update();
      }, error => {
        this.loading = false;
        this.helperFunctionsService.displayNotificationErrorAlert(error);
      });
  }

  onToggle() {
    let dropdown = $('.ajax-dropdown', this.el.nativeElement);
    this.active = !this.active;
    if (this.active) {
      dropdown.fadeIn()
      this.documentSub = this.renderer.listen('document', 'mouseup', (event) => {
        if (!this.el.nativeElement.contains(event.target)) {
          dropdown.fadeOut();
          this.active = false;
          this.documentUnsub()
        }
      });
    } else {
      dropdown.fadeOut()
      this.documentUnsub()
    }
  }

  update() {
    this.loading = true;

    this.apiSubscription = this.apiService.getNotifications()
      .subscribe(data => {
        this.activities = data;
        this.count = 0;

        this.activities[0].data.forEach(item => {
          item.isRead == false ? this.count++ : '';
          item.createdDate = item.createdDate.substring(0, 10);
        });

        this.currentActivity = data[0];
        this.lastUpdate = new Date()
        this.loading = false
      }, error => {
        this.helperFunctionsService.displayNotificationErrorAlert(error);
      });
  }

  documentUnsub() {
    this.documentSub && this.documentSub();
    this.documentSub = null
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { LanguageSelectorComponent } from 'app/shared/i18n/language-selector/language-selector.component';
import { ActivitiesNotificationComponent } from 'app/shared/layout/header/activities/activities-notification/activities-notification.component';
import { ActivitiesComponent } from 'app/shared/layout/header/activities/activities.component';
import { FullScreenComponent } from 'app/shared/layout/header/full-screen/full-screen.component';
import { HealthCheckComponent } from 'app/shared/layout/header/health-check/health-check.component';
import { UserOptionsComponent } from 'app/shared/layout/header/user-options/user-options.component';
import { PipesModule } from 'app/core/pipes/pipes.module';
import { UserModule } from 'app/shared/user/user.module';
import { UtilsModule } from 'app/shared/utils/utils.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { JoyrideModule } from 'ngx-joyride';
import { SaButtonModule } from '../sa-button/sa-button.module';
import { SaIconButtonModule } from "../sa-icon-button/sa-icon-button.module";
import { SaSearchBoxModule } from '../sa-search-box/sa-search-box.module';
import { SaHeaderRightButtonsComponent } from "./sa-header-right-buttons.component";

const UI = [
  SaIconButtonModule,
  SaSearchBoxModule,
  SaButtonModule
];

@NgModule({
  declarations: [
    SaHeaderRightButtonsComponent,
    HealthCheckComponent,
    LanguageSelectorComponent,
    ActivitiesComponent,
    ActivitiesNotificationComponent,
    FullScreenComponent,
    UserOptionsComponent],
  imports: [
    CommonModule,
    RouterModule,
    ...UI,
    FormsModule,
    BsDropdownModule,
    UtilsModule, PipesModule, I18nModule, UserModule, PopoverModule, RouterModule,
    JoyrideModule.forRoot()
  ],
  exports: [
    SaHeaderRightButtonsComponent
  ]
})
export class SaHeaderRightButtonsModule {
}

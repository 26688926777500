import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SaButtonComponent} from "./sa-button.component";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PipesModule } from 'app/core/pipes/pipes.module';

// const UI = [
// ];

@NgModule({
  declarations: [
    SaButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    PopoverModule.forRoot()
    // ...UI
  ],
  exports: [
    SaButtonComponent
  ]
})
export class SaButtonModule {
}

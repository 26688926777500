import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { RecentProjectsComponent } from "./recent-projects/recent-projects.component";


import { PipesModule } from "app/core/pipes/pipes.module";
import { I18nModule } from "app/shared/i18n/i18n.module";
import { UserModule } from "app/shared/user/user.module";
import { UtilsModule } from "app/shared/utils/utils.module";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ActivitiesMessageComponent, ActivitiesTaskComponent } from "./activities";
import { HeaderComponent } from "./header.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule,
    UtilsModule,
    PipesModule,
    I18nModule,
    UserModule,
    RouterModule
  ],
  declarations: [
    RecentProjectsComponent,
    CollapseMenuComponent,
    HeaderComponent,
    ActivitiesMessageComponent,
    ActivitiesTaskComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }

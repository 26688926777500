

export const languages: any = [
  {
    "key": "us",
    "alt": "English (US)",
    "title": "English"
  },
  // {
  //   "key": "gr",
  //   "alt": "Greek",
  //   "title": "Greek"
  // },
  {
    "key": "es",
    "alt": "Spanish",
    "title": "Español"
  },
  /*
 {
   "key": "fr",
   "alt": "France",
   "title": "Français"
 },
 {
   "key": "de",
   "alt": "German",
   "title": "Deutsch"
 },
 {
  "key": "jp",
  "alt": "Japan",
  "title": "日本語"
},
{
  "key": "cn",
  "alt": "China",
  "title": "中文"
},
{
  "key": "zh",
  "alt": "Chinese",
  "title": "汉语/漢語"
},
{
  "key": "pt",
  "alt": "Portugal",
  "title": "Portugal"
},
{
  "key": "ru",
  "alt": "Russia",
  "title": "Русский язык"
},
{
  "key": "kr",
  "alt": "Korea",
  "title": "한국어"
}*/
];

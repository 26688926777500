import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AppService } from "app/app.service";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'sa-menu-item',
  templateUrl: './sa-menu-item.component.html',
  styleUrls: ['./sa-menu-item.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100, style({ opacity: 1 }))
      ]),
    ])
  ]
})
export class SaMenuItemComponent implements OnChanges, OnInit {
  @Input() minimize: boolean = false; // Is menu minimized
  @Input() routerLink: string | undefined = undefined; // Router link of the menu item
  @Input() icon: string = 'chevron-circle-right'; // Menu item icon
  @Input() title: string = ''; // Title of the menu item
  @Input() subMenu: boolean = false; // True if this item is a submenu
  @Input() hasSubMenu: boolean = false; // True if this item has expandable submenus
  @Input() subMenuLevel: number = 0; // Which submenu level is this?
  @Input() secondaryMenu: boolean = false;
  @Input() itemColor: string = '#838383';
  @Input() menuTour: string = 'tour-start';
  @Input() tourDescription: string = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>(); // Click event of the menu item

  expanded: boolean = false;
  private _showText: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  showContent: any = true;

  constructor(private _appService: AppService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['minimize'] && typeof changes['minimize'].currentValue !== 'undefined') {
      if (changes['minimize'].currentValue === false) {
        this.showContent = true;
        this._showText.next(true);
      } else {
        this.showContent = false;
        this._showText.next(false);
      }
    }
  }

  ngOnInit() {
    this.debounceMenuItems();
  }

  debounceMenuItems() {
    this._showText.asObservable()
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(state => {
        this.showContent = state;
      });
  }

  expandMenuItem() {
    this.expanded = !this.expanded;
  }

  onClickFn() {
    if (this.routerLink) {
      if (this.title != "Dashboard" || this.secondaryMenu) {
        this._appService.minimizeMainMenu();
      }
      else {
        this._appService.maximizeMainMenu();
      }
    }

    this.onClick.emit();
  }
}

import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent {
  @Input() title;
  @Input() message;
  @Input() color;
}

import { Component, Input, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs/Rx';

@Component({
  selector: 'app-saved-status',
  templateUrl: './saved-status.component.html',
  styleUrls: ['./saved-status.component.css']
})
export class SavedStatusComponent {
  @Input() saved;
  @Input() showSaved = false;
  @Input() display = true;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CustomPopOverDirective } from './custom-popover.directive';
import { DecimalInputDirective } from './decimal-input.directive';
import { NumberCounterDirective } from './number-counter.directive';

@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
  ],
  declarations: [CustomPopOverDirective, NumberCounterDirective, DecimalInputDirective],
  exports: [CustomPopOverDirective, NumberCounterDirective, DecimalInputDirective]
})
export class DirectivesModule { }

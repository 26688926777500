export const environment = {
  production: true,

  firebase: {
  },

  debug: window["env.demo"]["debug"] || false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  URL: window["env.demo"]["URL"] || "Default",
  BASE_URL: window["env.demo"]["BASE_URL"] || "Default",
  LOGINURL: window["env.demo"]["LOGINURL"] || "Default",
  ContactToEmail: window["env.demo"]["ContactToEmail"],
  IdleTimeout: window["env.demo"]["IdleTimeout"] || "10000",
  WindowsAuthenticationMode: window["env.demo"]["WindowsAuthenticationMode"] || false,
  LdapAuthenticationMode: window["env.demo"]["LdapAuthenticationMode"] || false,
  DownloadScreeningImagesOnFrontEnd: window["env.demo"]["DownloadScreeningImagesOnFrontEnd"] || false,
  DownloadScreeningImagesOnFrontEndProxyUrl: window["env.demo"]["DownloadScreeningImagesOnFrontEndProxyUrl"] || ""
};

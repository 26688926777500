import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'sa-search-box',
  templateUrl: './sa-search-box.component.html',
  styleUrls: ['./sa-search-box.component.scss']
})
export class SaSearchBoxComponent {
  @Input() icon: string = 'search'; // Icon on the left side of the box
  @Input() placeholder: string = 'Search'; // Search input placeholder text
  @Input() width: string = '100%'; // With of the search bpx
  @Input() searchTerm: any;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>(); // Returns an event when box is clicked
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>(); // Returns the query when input is submitted
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from 'app/shared/i18n/i18n.module';
import { JoyrideModule } from 'ngx-joyride';
import { SaMenuItemModule } from "../sa-menu-item/sa-menu-item.module";
import { SaSearchBoxModule } from '../sa-search-box/sa-search-box.module';
import { SaMainLeftMenuComponent } from "./sa-main-left-menu.component";
import { SearchDirective } from './smart-menu.directive';

const UI = [
  SaMenuItemModule,
  SaSearchBoxModule
];

@NgModule({
  declarations: [
    SaMainLeftMenuComponent,
    SearchDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    JoyrideModule.forRoot(),
    ...UI
  ],
  exports: [
    SaMainLeftMenuComponent,
    SearchDirective
  ]
})
export class SaMainLeftMenuModule {
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _minimizeMainMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _hideGlobalTemplate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _scrollPosition: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _showTreeStructure: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  minimizeMainMenu(): void {
    this._minimizeMainMenu.next(true);
  }

  maximizeMainMenu(): void {
    this._minimizeMainMenu.next(false);
  }

  getMainMenuState$(): Observable<boolean> {
    return this._minimizeMainMenu.asObservable();
  }

  hideGlobalTemplate(): void {
    this._hideGlobalTemplate.next(true);
  }

  showGlobalTemplate(): void {
    this._hideGlobalTemplate.next(false);
  }

  getGlobalTemplateState$(): Observable<boolean> {
    return this._hideGlobalTemplate.asObservable();
  }

  setScrollPosition(scrollPosition: number): void {
    this._scrollPosition.next(scrollPosition);
  }
  getScrollPosition$(): Observable<number> {
    return this._scrollPosition.asObservable().pipe(
      debounceTime(100),
      distinctUntilChanged()
    );
  }

  showTreeStructure(): void {
    this._showTreeStructure.next(true);
  }

  hideTreeStructure(): void {
    this._showTreeStructure.next(false);
  }

  getTreeStructureState$(): Observable<boolean> {
    return this._showTreeStructure.asObservable();
  }
}

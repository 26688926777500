import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[custom-popover]'
})
export class CustomPopOverDirective {
  @Input('custom-popover') options: any;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    this._elRef.nativeElement.insertAdjacentHTML('beforeend', `<span popoverPlacement="top" triggers="mouseenter:mouseleave" popoverTitle="Popover activated on hover"
                  popover="Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
                  container="body">Test From Directive</span>`);
  }
}
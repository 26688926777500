import { Component, OnInit, Input } from '@angular/core';

@Component({

  selector: 'sa-big-breadcrumbs',
  template: `
   <div class="small-text-md"><h4 style="display:inline-block;" class="page-title txt-color-blueDark">
  {{items[0]}}
   <h4 style="display:inline-block;" *ngFor="let item of items.slice(1)"><i style="margin-left:4px" class="fa fa-angle-right">
  </i> {{item}}
  </h4>
</h4>
</div>
  `,
})
export class BigBreadcrumbsComponent implements OnInit {

  @Input() public icon: string;
  @Input() public items: Array<string>;
  

  constructor() { }

  ngOnInit() {
  }

}

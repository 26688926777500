<aside class="left-menu" [class.minimized]="minimize" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="logo">
    <img *ngIf="accountLogo && !minimize" [src]="'data:image/png;base64,' + accountLogo" alt="ComplyTek Logo" />
    <img *ngIf="minimize" src="assets/images/small-logo.png" alt="ComplyTek Small Logo" />
  </div>
  <!--If the variable hasActiveOnboardingAccount is not null it means that we got a response from the API so we are ready to draw the navigation menu without any loses-->
  <sa-search-box *ngIf="!this.minimize" [width]="'100%'" [searchTerm]="searchTerm"
    (onSubmit)="onSearchInputChange($event)">
  </sa-search-box>

  <div class="no-search-results" *ngIf="!hasSearchResults">
    <i class="fa fa-search" aria-hidden="true"></i>
    <span>{{'No Search Results'|i18n}}</span>
  </div>
  <div class="menu-list-items" *ngIf="(isPowerBiEnabled == false
        || isPowerBiEnabled == undefined 
        || (isPowerBiEnabled && powerBiReportsListInitiated)) && this.hasActiveOnboardingAccount != -1">
    <div class="top-section">
      <ul class="main-menu" appSearch [searchTerm]="searchTerm">
        <li class="menu-item">
          <sa-menu-item [menuTour]="'tour-home-page'" [tourDescription]="this.tourHomeDescription"
            routerLink="/main/dashboard" icon="tachometer-alt" title="Dashboard" [minimize]="minimize"
            itemColor="{{menuItemColor}}">
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasClientsShowPermissions && portalSetupType != 2">
          <sa-menu-item [menuTour]="'tour-know-your-clients'" [tourDescription]="this.tourKnowYourClientsDescription"
            routerLink="/main/clients" icon="user-shield" title="Know Your Clients" [minimize]="minimize"
            itemColor="{{menuItemColor}}">
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasDacSixClientsShowPermissions && portalSetupType === 2">
          <sa-menu-item [menuTour]="'tour-arrangements'" routerLink="/main/arrangements" icon="user-shield"
            title="DAC6 Clients" [minimize]="minimize" itemColor="{{menuItemColor}}">
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasKnowYourTransactionsShowPermissions" routerLinkActive="active">
          <sa-menu-item [menuTour]="'tour-know-your-transactions'"
            [tourDescription]="this.tourKnowYourTransactionsDescription"
            routerLink="/know-your-transactions/transactions" icon="exchange-alt" title="Know Your Transactions"
            [minimize]="minimize" itemColor="{{menuItemColor}}">
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasMyTasksShowPermissions" routerLinkActive="active">
          <sa-menu-item [menuTour]="'tour-my-tasks'" [tourDescription]="this.tourMyTasksDescription"
            routerLink="/my-tasks" icon="tasks" title="My Tasks" [minimize]="minimize" itemColor="{{menuItemColor}}">
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasKnowYourFirmsShowPermissions" routerLinkActive="active">
          <sa-menu-item [menuTour]="'tour-know-your-firms'" [tourDescription]="this.tourKnowYourFirmsDescription"
            routerLink="/know-your-firms/firms" icon="building" title="Know Your Firm" [minimize]="minimize"
            itemColor="{{menuItemColor}}">
          </sa-menu-item>
        </li>
        <li class="menu-item"
          *ngIf="isPowerBiEnabled == true && powerBiReportsList.length > 0 && powerBiReportsListInitiated == true">
          <sa-menu-item [menuTour]="'tour-power-bi'" [tourDescription]="this.tourPowerBiDashboardsDescription"
            icon="chart-pie" title="Power-BI Reports" [minimize]="minimize" [hasSubMenu]="true"
            itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li class="menu-item" *ngFor="let report of powerBiReportsList">
                <sa-menu-item routerLink="{{'/main/power-bi/report/' + report.component}}" [title]="report.title"
                  [subMenu]="true" [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
            </ul>
          </sa-menu-item>
        </li>
        <li class="menu-item"
          *ngIf="(hasProcessesShowPermissions && hasProcessesExecPermissions) || authService.isAdmin == true">
          <sa-menu-item [menuTour]="'tour-processes'" [tourDescription]="this.tourProcessesDescription" icon="play"
            title="Processes" [minimize]="minimize" [hasSubMenu]="true" itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li class="menu-item">
                <sa-menu-item title="Client Profiling" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li class="menu-item" *ngFor="let process of clientProfilingProcessesList">
                      <sa-menu-item *ngIf="process.isVisible" routerLink="{{'/processes/' + process.component}}"
                        [title]="process.title" [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item title="Transaction Monitoring & Actions" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let process of transactionMonitoringAndActionsProcessesList">
                      <sa-menu-item *ngIf="process.isVisible" routerLink="{{'/processes/' + process.component}}"
                        [title]="process.title" [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item title="Screening & Electronic Identification" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let process of screeningAndElectronicIdentificationProcessesList">
                      <sa-menu-item *ngIf="process.isVisible" routerLink="{{'/processes/' + process.component}}"
                        [title]="process.title" [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
              <li class="menu-item" *ngIf="(this.hasOnboardingProcessesExecPermissions && this.hasActiveOnboardingAccount) ||
                this.authService.isAdmin">
                <sa-menu-item title="Onboarding Portal" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngIf="portalSetupType != 2">
                      <sa-menu-item *ngIf="handleOnboardingClientsIsVisible"
                        routerLink="/processes/handle-onboarding-clients" title="Handle Onboarding Clients"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>

                    <li *ngIf="handleOnboardingClientsIsVisible">
                      <sa-menu-item routerLink="/processes/handle-onboarding-client-registrations"
                        [title]="handleOnboardingRegistrationsText" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>

                    <li *ngIf="handleOnboardingUsersIsVisible">
                      <sa-menu-item routerLink="/processes/handle-onboarding-users" title="Handle Onboarding Users"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li *ngIf="handleOnboardingUsersIsVisible">
                      <sa-menu-item routerLink="/processes/handle-clients-portal-users"
                        title="Handle Clients Portal Users" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item title="Other" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let process of otherProcessesList">
                      <sa-menu-item *ngIf="process.isVisible" routerLink="{{'/processes/' + process.component}}"
                        [title]="process.title" [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
            </ul>
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasManagementShowPermissions">
          <sa-menu-item [menuTour]="'tour-management'" [tourDescription]="this.tourManagementDescription"
            icon="briefcase" title="Management" [minimize]="minimize" [hasSubMenu]="true" itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li *ngFor="let management of this.managementList">
                <sa-menu-item *ngIf="authService.isAdmin == true || management.isVisible"
                  routerLink="{{'/management/' + management.component}}" [title]="management.title" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
            </ul>
          </sa-menu-item>
        </li>
        <li class="menu-item"
          *ngIf="(hasReportsShowPermissions && hasReportsExecPermissions) || authService.isAdmin == true">
          <sa-menu-item [menuTour]="'tour-reports'" [tourDescription]="this.tourReportsDescription"
            icon="clipboard-list" title="Reports/Lists" [minimize]="minimize" [hasSubMenu]="true"
            itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li class="menu-item">
                <sa-menu-item title="Client Profiling" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of clientProfilingReportsList">
                      <sa-menu-item *ngIf="(authService.checkUserPermission(report.canExecute,'canShow')
                  && authService.checkUserPermission(report.canExecute,'canExecute')) || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Client Evaluations & Documents" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of clientEvaluationAndDocumentsReportsList">
                      <sa-menu-item *ngIf="(authService.checkUserPermission(report.canExecute,'canShow')
                  && authService.checkUserPermission(report.canExecute,'canExecute')) || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Transaction Monitoring & Actions" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of transactionMonitoringAndActionsReportsList">
                      <sa-menu-item *ngIf="(authService.checkUserPermission(report.canExecute,'canShow')
                  && authService.checkUserPermission(report.canExecute,'canExecute')) || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Screening & Electronic Identification" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of screeningAndElectronicIdentificationReportsList">
                      <sa-menu-item *ngIf="(authService.checkUserPermission(report.canExecute,'canShow')
                  && authService.checkUserPermission(report.canExecute,'canExecute')) || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item" *ngIf="this.hasActiveOnboardingAccount">
                <sa-menu-item title="Onboarding Portal" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of onboardingPortalReportsList">
                      <sa-menu-item *ngIf="(authService.checkUserPermission(report.canExecute,'canShow')
                  && authService.checkUserPermission(report.canExecute,'canExecute')) || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Machine Learning" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of mlReportsList">
                      <sa-menu-item
                        *ngIf="authService.checkUserPermission(report.canExecute,'canExecute') || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Other" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let report of otherReportsList">
                      <sa-menu-item *ngIf="(authService.checkUserPermission(report.canExecute,'canShow')
                  && authService.checkUserPermission(report.canExecute,'canExecute')) || authService.isAdmin == true"
                        routerLink="{{'/reports/' + report.component}}" [title]="report.title" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
            </ul>
          </sa-menu-item>
        </li>
      </ul>
    </div>
    <div class="bottom-section">
      <ul class="main-menu" appSearch [searchTerm]="searchTerm">
        <li class="menu-item"
          *ngIf="(hasSettingsShowPermissions && hasSettingsReadPermissions) || this.authService.isAdmin">
          <sa-menu-item [menuTour]="'tour-settings'" [tourDescription]="this.tourSettingsDescription" icon="cog"
            title="Settings" [minimize]="minimize" [hasSubMenu]="true" itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li class="menu-item">
                <sa-menu-item title="Client Profiling" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let setting of clientProfilingSettingsList">
                      <sa-menu-item routerLink="{{'/settings/' + setting.component}}" [title]="setting.title"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Countries" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let setting of countriesSettingsList">
                      <sa-menu-item routerLink="{{'/settings/' + setting.component}}" [title]="setting.title"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Transaction Monitoring & Actions" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let setting of transactionMonitoringAndActionsSettingsList">
                      <sa-menu-item routerLink="{{'/settings/' + setting.component}}" [title]="setting.title"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Evaluation & Questionnaire" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let setting of evaluationAndQuestionnaireSettingsList">
                      <sa-menu-item routerLink="{{'/settings/' + setting.component}}" [title]="setting.title"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Know Your Firm" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let setting of firmsSettingsList">
                      <sa-menu-item routerLink="{{'/settings/' + setting.component}}" [title]="setting.title"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Templates & Report Setups" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngFor="let setting of templatesAndReportSetups">
                      <sa-menu-item routerLink="{{'/settings/' + setting.component}}" [title]="setting.title"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

            </ul>
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="hasAdministrationShowPermissions || this.authService.isAdmin">
          <sa-menu-item [menuTour]="'tour-administration'" [tourDescription]="this.tourAdministrationDescription"
            icon="user-cog" title="Administration" [minimize]="minimize" [hasSubMenu]="true"
            itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li class="menu-item" *ngIf="hasAccountManagementReadPermissions">
                <sa-menu-item routerLink="/administration/account" title="Account" [subMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item" *ngIf="hasReleaseNotesExecPermissions">
                <sa-menu-item routerLink="/administration/release-notes" title="Release Notes" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="User Management" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngIf="hasDataAccessGroupsReadPermissions">
                      <sa-menu-item routerLink="/administration/data-access-groups" title="Data Access Groups"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li *ngIf="hasRoleManagementReadPermissions">
                      <sa-menu-item routerLink="/administration/roles" title="Roles" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li *ngIf="hasUserManagementReadPermissions">
                      <sa-menu-item routerLink="/administration/users" title="Users" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Audit Trail Extractions & Setup" [subMenu]="true" [hasSubMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li *ngIf="hasAuditLogsExecPermissions">
                      <sa-menu-item routerLink="/administration/audit-logs-report" title="Audit Trail" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li *ngIf="hasFieldToTrackUpdatesExecPermissions">
                      <sa-menu-item routerLink="/administration/field-log-setups" title="Audit Trail Setups"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasAutoRunScriptsExecPermissions">
                      <sa-menu-item routerLink="/administration/auto-run-scripts" title="Auto Run Scripts Trail"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasAdministrationShowPermissions">
                      <sa-menu-item routerLink="/administration/custom-logs-report" title="Custom Logs Trail"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasEmailLogsExecPermissions">
                      <sa-menu-item routerLink="/administration/emails-logs-report" title="Email Audit Trail"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasExternalNotificationsLogsExecPermissions">
                      <sa-menu-item routerLink="/administration/external-notifications-logs-report"
                        title="External Notifications Audit Trail" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasHangfireJobLogExecPermissions">
                      <sa-menu-item routerLink="/administration/hangfire-job-log-report"
                        title="Hangfire Job Audit Trail" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasLoginLogsExecPermissions">
                      <sa-menu-item routerLink="/administration/login-log-report" title="Login Audit Trail"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>

              <li class="menu-item">
                <sa-menu-item title="Other Settings" [subMenu]="true" [hasSubMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                  <ul class="main-menu">
                    <li class="menu-item" *ngIf="hasDynamicReportsReadPermissions">
                      <sa-menu-item routerLink="/administration/check-transaction-screening"
                        title="Check Transaction Screening" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasDynamicReportsReadPermissions">
                      <sa-menu-item routerLink="/administration/system-dynamic-reports" title="Dynamic Reports"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasEventEnginesReadPermissions">
                      <sa-menu-item routerLink="/administration/event-engines" title="Event Engines" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasExternalCredentialsSetupReadPermissions">
                      <sa-menu-item routerLink="/administration/external-credentials-setups"
                        title="External Credentials Setups" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasInternalBlacklistManagementReadPermissions">
                      <sa-menu-item routerLink="/administration/internal-blacklists-setup"
                        title="Internal Blacklists Setup" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasQueryTemplatesExecPermissions">
                      <sa-menu-item routerLink="/administration/query-templates" title="Query Templates"
                        [subMenu]="true" [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasRuleManagementReadPermissions">
                      <sa-menu-item routerLink="/administration/rules" title="Rules" [subMenu]="true" [subMenuLevel]="2"
                        itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item">
                      <sa-menu-item routerLink="/administration/task-scheduler" title="Task Scheduler" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                    <li class="menu-item" *ngIf="hasWorkflowReadPermissions">
                      <sa-menu-item routerLink="/administration/workflows" title="Workflows" [subMenu]="true"
                        [subMenuLevel]="2" itemColor="{{menuItemColor}}">
                      </sa-menu-item>
                    </li>
                  </ul>
                </sa-menu-item>
              </li>
            </ul>
          </sa-menu-item>
        </li>
        <li class="menu-item" *ngIf="authService.isAdmin">
          <sa-menu-item icon="cogs" title="System Settings" [minimize]="minimize" [hasSubMenu]="true"
            itemColor="{{menuItemColor}}">
            <ul class="main-menu">
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/account" title="Account" [subMenu]="true" [subMenuLevel]="1"
                  itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/advanced-dashboard/Designer"
                  title="Advanced Dashboard Designer" [subMenu]="true" [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/case-statuses" title="Case Statuses" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/defaults" title="Defaults" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/id-verification-data-supports" title="eIDV Data Supports"
                  [subMenu]="true" [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/event-engines-setup" title="Event Engines Setup"
                  [subMenu]="true" [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/languages-setup" title="Language Setup" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/parameter-queries" title="Parameter Queries" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/portal-setup" title="Portal Setup" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/system-logs" title="System Logs" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>
              <li class="menu-item">
                <sa-menu-item routerLink="/system-settings/hangfire-details" title="Hangfire Details" [subMenu]="true"
                  [subMenuLevel]="1" itemColor="{{menuItemColor}}">
                </sa-menu-item>
              </li>

            </ul>
          </sa-menu-item>
        </li>
      </ul>
    </div>
  </div>
</aside>
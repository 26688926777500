import { Component, Input, ViewChild, ElementRef, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import {ApiService, AuthService, NotificationService} from 'app/core/services';
import { I18nService } from 'app/shared/i18n/i18n.service';




@Component({
  selector: 'app-custom-chat',
  templateUrl: './custom-chat.component.html',
  styleUrls: ['./custom-chat.component.css']
})
export class CustomChatComponent {
  @Input() internalNotes;
  @Input() showHeader;
  @Input() isChainalysisActive: boolean = false;
  @Input() chainAnalysisAlertIdentifiers: any = [];

  @Input('isViewingHistory') public isViewingHistory = false;
  @ViewChildren('notesLoop') noteItems: QueryList<any>;
  @ViewChild('notesbody') private myScrollContainer: ElementRef;

  @Output() addMessageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteMessageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() setChainalysisReviewStatus: EventEmitter<any> = new EventEmitter<any>();

  selectedChainalysisAlertReviewStatus: any;
  chainalysisAlertReviewStatusEnum: any = [];

  noteText: string = "";
  scrolled: boolean = false;
  hasDeletePermissions: boolean = false;
  hasCreatePermissions: boolean = false;
  placeholder: string = 'Write a note...';

  constructor(private notificationService: NotificationService
    , private authService: AuthService
    , private i18nService: I18nService
    , private apiService: ApiService) {
    this.hasCreatePermissions = this.authService.checkUserPermission('ClientInternalNotes', 'canCreate');
    this.hasDeletePermissions = this.authService.checkUserPermission('ClientInternalNotes', 'canDelete');
  }

  ngOnInit(){
    if (this.isViewingHistory) this.placeholder = 'Writing notes is disabled'
  }
  //Scrols the list once notes are populated
  ngAfterViewInit() {
    this.noteItems.changes.subscribe(t => {
      this.scrollToBottom();
    });

    const sub = this.apiService.getChainalysisAlertReviewStatusEnum().subscribe(data => {
      this.chainalysisAlertReviewStatusEnum = data;
      sub.unsubscribe();
    });
  }

  hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  scrollToBottom() {
    if (this.scrolled == true) {
      return;
    }
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      this.scrolled = true;
    } catch (err) { }
  }

  addMessage() {
    if (this.isChainalysisActive) this.setChainalysisReviewStatus.emit(this.selectedChainalysisAlertReviewStatus);
    this.addMessageEvent.emit(this.noteText);
  }

  MessageAdded() {
    this.scrolled = false;
    this.noteText = "";
  }

  deleteInternalNote(noteId) {
    this.notificationService.smartMessageBox({
      title: this.i18nService.getTranslation("Delete Internal Note?"),
      content: this.i18nService.getTranslation("Are you sure you want to delete this internal note?"),
      buttons: `[${this.i18nService.getTranslation('No')}][${this.i18nService.getTranslation('Yes')}]`
    }, (ButtonPressed) => {
      if (ButtonPressed === this.i18nService.getTranslation("Yes")) {
        this.deleteMessageEvent.emit(noteId);
      }
    });
  }

}

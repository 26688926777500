import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService, AuthService, HelperFunctionsService } from 'app/core/services';
import { I18nService } from "../i18n.service";
import { languages } from '../languages.model';

@Component({
  selector: 'sa-language-selector',
  templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {

  public languages: Array<any>;
  public currentLanguage: any;

  constructor(private i18n: I18nService, private apiService: ApiService
    , public authService: AuthService
    , public helperFunctionsService: HelperFunctionsService) {

    this.currentLanguage = this.authService.user ? this.authService.user.languageObj : null;
  }

  ngOnInit() {
    this.languages = languages;
    this.i18n.setLanguage(this.currentLanguage);
  }

  setLanguage(language) {
    this.currentLanguage = language;
    this.editUserLanguage(this.currentLanguage);
    this.i18n.setLanguage(language);
    if (this.authService.user)
      this.authService.user.languageObj = this.currentLanguage;
  }

  editUserLanguage(language: any) {
    const languageService = this.apiService.updateUserLanguage(language)
      .subscribe(data => {
        languageService.unsubscribe();
      },
        error => {
          this.helperFunctionsService.displayNotificationErrorAlert(error);
          languageService.unsubscribe();
        });
  }

}

import { Component, OnInit } from '@angular/core';
import { ApiService, AuthService, HelperFunctionsService } from 'app/core/services';
import { JoyrideService } from 'ngx-joyride';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';
import { forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sa-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  public hasActiveOnboardingAccount = -1;
  public menuItems: any;
  public menuItemsRestore: any;
  public clientProfilingReportsList = [];
  public transactionMonitoringAndActionsReportsList = [];
  public clientEvaluationAndDocumentsReportsList = [];
  public screeningAndElectronicIdentificationReportsList = [];
  public onboardingPortalReportsList = [];
  public otherReportsList = [];
  public powerBiReportsList: any = [];
  public mlReportsList = [];

  public clientProfilingSettingsList = [];
  public countriesSettingsList = [];
  public transactionMonitoringAndActionsSettingsList = [];
  public evaluationAndQuestionnaireSettingsList = [];
  public templatesAndReportSetups = [];
  public firmsSettingsList = [];
  public managementList = [];

  public clientProfilingProcessesList = [];
  public transactionMonitoringAndActionsProcessesList = [];
  public screeningAndElectronicIdentificationProcessesList = [];
  public onboardingPortalProcessesList = [];
  public otherProcessesList = [];

  public dacSixClientsList = [];
  public searchTerm: string = null;
  hasSearchResults: boolean = true;

  public portalSetupType: any = null;
  public handleOnboardingRegistrationsText: string = "";

  public hasUserManagementReadPermissions: boolean = false;
  public hasRoleManagementReadPermissions: boolean = false;
  public hasRuleManagementReadPermissions: boolean = false;
  public hasInternalBlacklistManagementReadPermissions: boolean = false;
  public hasSettingsReadPermissions: boolean = false;
  public hasClientsReadPermissions: boolean = false;
  public hasClientCasesReadPermissions: boolean = false;
  public hasDataAccessGroupsReadPermissions: boolean = false;
  public hasWorkflowReadPermissions: boolean = false;
  public hasDynamicReportsReadPermissions: boolean = false;

  public hasCheckTransactionScreeningReadPermissions: boolean = false;
  public hasEventEnginesReadPermissions: boolean = false;
  public hasAccountManagementReadPermissions: boolean = false;
  public hasExternalCredentialsSetupReadPermissions: boolean = false;

  public hasAuditLogsExecPermissions: boolean = false;
  public hasLoginLogsExecPermissions: boolean = false;
  public hasExternalNotificationsLogsExecPermissions: boolean = false;
  public hasEmailLogsExecPermissions: boolean = false;
  public hasHangfireJobLogExecPermissions: boolean = false;
  public hasAutoRunScriptsExecPermissions: boolean = false;
  public hasOnboardingProcessesExecPermissions: boolean = false;
  public hasStagingAreaInfoExecPermissions: boolean = false;
  public hasFieldToTrackUpdatesExecPermissions: boolean = false;
  public hasQueryTemplatesExecPermissions: boolean = false;
  public hasReleaseNotesExecPermissions: boolean = false;
  public hasReportsExecPermissions: boolean = false;
  public hasProcessesExecPermissions: boolean = false;

  public hasManagementCasesExecutePermissions: boolean = false;
  public hasManagementCasesWorkflowExecutionPermissions: boolean = false;
  public hasManagementAdminTasksExecutePermissions: boolean = false;
  public hasManagementMyTasksExecutionPermissions: boolean = false;
  public hasManagementRuleWhielistingExecutionPermissions: boolean = false;
  public hasManagementSegmentationExecutionPermissions: boolean = false;

  public hasReportsShowPermissions: boolean = false;
  public hasMLReportsShowPermissions: boolean = false;
  public hasProcessesShowPermissions: boolean = false;
  public hasClientsShowPermissions: boolean = false;
  public hasKnowYourTransactionsShowPermissions: boolean = false;
  public hasKnowYourFirmsShowPermissions: boolean = false;
  public hasAdministrationShowPermissions: boolean = false;
  public hasSettingsShowPermissions: boolean = false;
  public hasManagementShowPermissions: boolean = false;
  public hasDacSixClientsShowPermissions: boolean = false;

  public powerBiReportsListInitiated: boolean = false;

  // Guided Tour 
  public tourHomeDescription = 'This is a form where the user can easily view critical information regarding the clients.';
  public tourKnowYourClientsDescription = 'This is a form where the user can view the list of existing clients and create a new client, either an individual or a legal entity.';
  public tourKnowYourTransactionsDescription = 'This is a form where the user can view the list of all the Transactions imported on RegTek+ and add new transactions.';
  public tourManagementDescription = 'This is a form which can be used to manage all cases related to transaction monitoring rules. Several filtering can be made in order to narrow down the desired results based on the user’s needs.';
  public tourReportsDescription = 'This is a form where the user can generate multiple reports, related to Evaluations, Transaction Monitoring, Client Profiling and other.';
  public tourProcessesDescription = 'This is a form where the user can run massive procedures for the clients’ evaluations, cases and actions.';
  public tourPowerBiDashboardsDescription = 'This section can be used to view the dashboards generated via Power BI.';
  public tourSettingsDescription = 'This is a form where the user can add, edit and/or amend all the parameters of the RegTek+ platform. These parameters include, among others, the set up of the AML risk model, Transaction monitoring Rules, Criteria, Evaluation grades.';
  public tourAdministrationDescription = 'This is a form where the user accounts are created as well as the role of each user and the data access.';

  hasFirstLogin: boolean = false;

  isPowerBiEnabled: boolean = false;
  powerBiReports: any = [];

  constructor(public authService: AuthService
    , public apiService: ApiService
    , public helperFunctionsService: HelperFunctionsService
    , private readonly joyrideService: JoyrideService
  ) {
    //If portal setup is 1 then we display Clients module else we display Dac6 Clients module
    this.getPortalSetupSelection();
  }

  ngOnInit(): void {
    // This statement is needed in order to not lose the isadmin flag value on refresh
    this.authService.IsAdmin();

    this.authService.userObservable
      .pipe(
        filter((user) => user && user.username),
        take(1)
      )
      .subscribe((_) => {
        //! We are entering this scope once the user has been initialized
        this.doCheckUserPermission();
      });

    this.initiateReports();
    this.initiateManagement();
    this.initiateSettings();
    this.initiateProcesses();

    this.dacSixClientsList = [
      {
        component: "dacSixClient",
        title: "DAC6 Clients",
        canShow: "DacSixClients",
      },
    ];

    this.fetchData();
  }

  fetchData() {
    forkJoin({
      userFirstLoginFlag: this.apiService.getUserHasFirstLoginFlag(),
      isPowerBiActive: this.apiService.isPowerBiActive()
    }).subscribe(data => {
      this.hasFirstLogin = data.userFirstLoginFlag.hasFirstLogin;

      if (!this.hasFirstLogin) {
        this.startTour();
        this.updateUserFirstLoginFlag();
      }

      this.isPowerBiEnabled = data.isPowerBiActive;

      if (this.isPowerBiEnabled == true) {
        const sub = this.apiService.getPowerBiAvailableReports().subscribe(data => {
          this.powerBiReports = data;
          this.initiatePowerBIReports();
          sub.unsubscribe();
        }, error => {
          this.helperFunctionsService.displayNotificationErrorAlert(error);
          sub.unsubscribe();
        });
      }

    }, error => {
      this.helperFunctionsService.displayNotificationErrorAlert(error);
    });
  }

  getPortalSetupSelection() {
    forkJoin({
      getPortalSetupType: this.apiService.getPortalSetupType(),
      hasActiveOnboardingAccount: this.apiService.hasActiveOnboardingAccount()
    })
      .subscribe(
        data => {
          this.portalSetupType = data.getPortalSetupType.setup;
          this.hasActiveOnboardingAccount = data.hasActiveOnboardingAccount;

          if (data.getPortalSetupType && data.getPortalSetupType.setup == 1) {
            this.handleOnboardingRegistrationsText = "Handle Onboarding Client Registrations";
          } else if (data.getPortalSetupType && data.getPortalSetupType.setup == 2) {
            this.handleOnboardingRegistrationsText = "Handle Arrangements";
          }
          else if (data.getPortalSetupType && data.getPortalSetupType.setup == 3) {
            this.handleOnboardingRegistrationsText = "Handle Onboarding Questionnaires";
          }

          this.helperFunctionsService.updateHasActiveOnboardingAccount(data.hasActiveOnboardingAccount);
        },
        error => {
          this.helperFunctionsService.updateHasActiveOnboardingAccount(false);
        });
  }

  doCheckUserPermission() {
    //----------------------------------START GENERAL MODULES----------------------------------//
    this.hasUserManagementReadPermissions = this.authService.checkUserPermission('UserManagement', 'canRead');
    this.hasRoleManagementReadPermissions = this.authService.checkUserPermission('RoleManagement', 'canRead');
    this.hasRuleManagementReadPermissions = this.authService.checkUserPermission('RuleManagement', 'canRead');
    this.hasInternalBlacklistManagementReadPermissions = this.authService.checkUserPermission('InternalBlacklistManagement', 'canRead');
    this.hasSettingsReadPermissions = this.authService.checkUserPermission('Settings', 'canRead');
    this.hasDataAccessGroupsReadPermissions = this.authService.checkUserPermission('DataAccessGroups', 'canRead');
    this.hasEventEnginesReadPermissions = this.authService.checkUserPermission('EventEngines', 'canRead');
    this.hasAccountManagementReadPermissions = this.authService.checkUserPermission('AccountManagement', 'canRead');
    this.hasWorkflowReadPermissions = this.authService.checkUserPermission('Workflows', 'canRead');
    this.hasDynamicReportsReadPermissions = this.authService.checkUserPermission('DynamicReports', 'canRead');
    this.hasCheckTransactionScreeningReadPermissions = this.authService.checkUserPermission('CheckTransactionScreening', 'canRead');
    this.hasExternalCredentialsSetupReadPermissions = this.authService.checkUserPermission('ExternalCredentialsSetups', 'canRead');
    //----------------------------------END GENERAL MODULES----------------------------------//

    //----------------------------------START CLIENT MODULES----------------------------------//
    this.hasClientsReadPermissions = this.authService.checkUserPermission('Clients', 'canShow');
    this.hasClientCasesReadPermissions = this.authService.checkUserPermission('ClientCases', 'canShow');
    //----------------------------------END CLIENT MODULES----------------------------------//

    //----------------------------------START MANAGEMENT COMPONENTS----------------------------------//
    this.hasManagementCasesExecutePermissions = this.authService.checkUserPermission('ManagementCases', 'canCreate')
      || this.authService.checkUserPermission('ManagementCases', 'canUpdate')
      || this.authService.checkUserPermission('ManagementCases', 'canDelete');
    this.hasManagementCasesWorkflowExecutionPermissions = this.authService.checkUserPermission('ManagementCasesWorkflow', 'canCreate')
      || this.authService.checkUserPermission('ManagementCasesWorkflow', 'canUpdate')
      || this.authService.checkUserPermission('ManagementCasesWorkflow', 'canDelete');
    this.hasManagementAdminTasksExecutePermissions = this.authService.checkUserPermission('ManagementAdminTasks', 'canCreate')
      || this.authService.checkUserPermission('ManagementAdminTasks', 'canUpdate')
      || this.authService.checkUserPermission('ManagementAdminTasks', 'canDelete');
    this.hasManagementMyTasksExecutionPermissions = this.authService.checkUserPermission('ManagementMyTasks', 'canCreate')
      || this.authService.checkUserPermission('ManagementMyTasks', 'canUpdate')
      || this.authService.checkUserPermission('ManagementMyTasks', 'canDelete');
    this.hasManagementRuleWhielistingExecutionPermissions = this.authService.checkUserPermission('ManagementRulesWhitelisting', 'canCreate')
      || this.authService.checkUserPermission('ManagementRulesWhitelisting', 'canUpdate')
      || this.authService.checkUserPermission('ManagementRulesWhitelisting', 'canDelete');
    this.hasManagementSegmentationExecutionPermissions = this.authService.checkUserPermission('ManagementSegmentation', 'canCreate')
      || this.authService.checkUserPermission('ManagementSegmentation', 'canUpdate')
      || this.authService.checkUserPermission('ManagementSegmentation', 'canDelete');
    //----------------------------------START MANAGEMENT COMPONENTS----------------------------------//

    //----------------------------------START INTERNAL PROCESSES MODULES----------------------------------//
    this.hasAuditLogsExecPermissions = this.authService.checkUserPermission('AuditLog', 'canExecute');
    this.hasLoginLogsExecPermissions = this.authService.checkUserPermission('LoginLog', 'canExecute');
    this.hasExternalNotificationsLogsExecPermissions = this.authService.checkUserPermission('ExternalNotificationLog', 'canExecute');
    this.hasEmailLogsExecPermissions = this.authService.checkUserPermission('EmailLog', 'canExecute');
    this.hasHangfireJobLogExecPermissions = this.authService.checkUserPermission('HangfireJobLog', 'canExecute');
    this.hasAutoRunScriptsExecPermissions = this.authService.checkUserPermission('AutoRunScripts', 'canExecute');
    this.hasOnboardingProcessesExecPermissions = this.authService.checkUserPermission('OnboardingProcesses', 'canExecute');
    this.hasStagingAreaInfoExecPermissions = this.authService.checkUserPermission('StagingAreaInfo', 'canExecute');
    this.hasFieldToTrackUpdatesExecPermissions = this.authService.checkUserPermission('FieldLogSetups', 'canExecute');
    this.hasQueryTemplatesExecPermissions = this.authService.checkUserPermission('QueryTemplates', 'canExecute');
    this.hasReleaseNotesExecPermissions = this.authService.checkUserPermission('ReleaseNotes', 'canExecute');
    this.hasReportsExecPermissions = this.authService.checkUserPermission('Reports', 'canExecute');
    this.hasProcessesExecPermissions = this.authService.checkUserPermission('Processes', 'canExecute');
    //----------------------------------END INTERNAL PROCESSES MODULES----------------------------------//

    //----------------------------------START MODULES----------------------------------//
    this.hasClientsShowPermissions = this.authService.checkCanShowModule('ClientsModule', 'canShow');
    this.hasManagementShowPermissions = this.authService.checkCanShowModule('ManagementModule', 'canShow');
    this.hasReportsShowPermissions = this.authService.checkCanShowModule('ReportsListsModule', 'canShow');
    this.hasProcessesShowPermissions = this.authService.checkCanShowModule('ProcessesModule', 'canShow');
    this.hasSettingsShowPermissions = this.authService.checkCanShowModule('SettingsModule', 'canShow');
    this.hasAdministrationShowPermissions = this.authService.checkCanShowModule('AdministrationModule', 'canShow');
    this.hasDacSixClientsShowPermissions = this.authService.checkCanShowModule('DacSixClientsModule', 'canShow');
    this.hasKnowYourTransactionsShowPermissions = this.authService.checkCanShowModule('LiveTransactionsModule', 'canShow');
    this.hasKnowYourFirmsShowPermissions = this.authService.checkCanShowModule('FirmsModule', 'canShow');
    //----------------------------------END MODULES----------------------------------//
  }

  initiateReports() {
    //*                                             ================= REPORTS =================
    this.clientProfilingReportsList = [
      // Client Profiling
      { component: 'clients-general-info-report', category: 'Client Profiling', title: 'Clients General Info', canExecute: 'ClientsGeneralInfoReport' },
      { component: 'clients-report', category: 'Client Profiling', title: 'Clients Report', canExecute: 'ClientsReport' },
      { component: 'profiles-report', category: 'Client Profiling', title: 'Profiles Report', canExecute: 'ProfilesReport' },
      { component: 'entity-position-report', category: 'Client Profiling', title: 'Entity Position Report', canExecute: 'EntityPositionReport' },
      { component: 'statutory-information-report', category: 'Client Profiling', title: 'Statutory Information Report', canExecute: 'StatutoryInformationReport' },
      { component: 'ubo-of-clients-report', category: 'Client Profiling', title: 'UBO of Clients Report', canExecute: 'UboOfClientsReport' },
    ]

    this.mlReportsList = [
      { component: 'classification-means-report', category: 'Machine Learning', title: 'Segmentation Values', canExecute: 'showMLReportsLists' }
    ]

    this.clientEvaluationAndDocumentsReportsList = [
      //Client Evaluations & Documents
      { component: 'last-evaluation-analysis-report', category: 'Client Evaluations & Documents', title: 'Last Evaluation Analysis Report', canExecute: 'LastEvaluationAnalysisReport' },
      { component: 'last-evaluation-results-report', category: 'Client Evaluations & Documents', title: 'Last Evaluation Results Report', canExecute: 'LastEvaluationResultsReport' },
      { component: 'follow-up-evaluations-report', category: 'Client Evaluations & Documents', title: 'Pending and Expired Evaluations Report', canExecute: 'PendingAndExpiredEvaluationsReport' },
      { component: 'clients-old-evaluation-per-criterion-answer-report', category: 'Client Evaluations & Documents', title: 'Clients Old Evaluation Per Criterion Answer Report', canExecute: 'ClientsOldEvaluationPerCriterionAnswerReport' },
      { component: 'clients-per-criterion-answer-report', category: 'Client Evaluations & Documents', title: 'Clients Per Criterion Answer Report', canExecute: 'ClientsPerCriterionAnswerReport' },
      { component: 'clients-per-question-answer-report', category: 'Client Evaluations & Documents', title: 'Clients Per Question Answer Report', canExecute: 'ClientsPerQuestionAnswerReport' },
      { component: 'country-evaluation-grades-histories-report', category: 'Client Evaluations & Documents', title: 'Country Evaluation Grades History Report', canExecute: 'CountryEvaluationGradesHistoryReport' },
      { component: 'documents-report', category: 'Client Evaluations & Documents', title: 'Documents Report', canExecute: 'DocumentsReport' },
      { component: 'pending-compliance-officer-evaluation-review-report', category: 'Client Evaluations & Documents', title: 'Pending Compliance Officer Evaluation Review Report', canExecute: 'PendingComplianceOfficerEvaluationReviewReport' }
    ]

    this.transactionMonitoringAndActionsReportsList = [
      //Transaction Monitoring & Actions
      { component: 'transactions-evaluation-report', category: 'Transaction Monitoring & Actions', title: 'Post Transactions', canExecute: 'TransactionsEvaluationReport' },
      { component: 'cases-report', category: 'Transaction Monitoring & Actions', title: 'Cases Report', canExecute: 'CasesReport' },
      { component: 'cases-per-rule-report', category: 'Transaction Monitoring & Actions', title: 'Cases Per Rule Report', canExecute: 'CasesPerRuleReport' },
      { component: 'dynamic-reports', category: 'Transaction Monitoring & Actions', title: 'Dynamic Reports', canExecute: 'DynamicReports' },
      { component: 'customer-activity-per-scenario-report', category: 'Transaction Monitoring & Actions', title: 'Customer Activity Per Scenario Report', canExecute: 'CustomerActivityPerScenarioReport' },
      { component: 'scenario-activity-report', category: 'Transaction Monitoring & Actions', title: 'Scenario Activity Report', canExecute: 'ScenarioActivityReport' },
      { component: 'actions-report', category: 'Transaction Monitoring & Actions', title: 'Actions Report', canExecute: 'ActionsReport' },
      { component: 'cases-overview-report', category: 'Other', title: 'Cases Overview', canExecute: 'GetCasesOverviewReport' }
    ]

    this.screeningAndElectronicIdentificationReportsList = [
      //Screening & Electronic Identification
      { component: 'screening-list-report', category: 'Screening & Electronic Identification', title: 'Screening List Report', canExecute: 'ScreeningListReport' },
      { component: 'detailed-screening-list-report', category: 'Screening & Electronic Identification', title: 'Detailed Screening List Report', canExecute: 'DetailedScreeningListReport' },
      { component: 'ongoing-monitoring-search-history-report', category: 'Screening & Electronic Identification', title: 'Ongoing Monitoring Search History Report', canExecute: 'OngoingMonitoringSearchHistoryReport' },
      { component: 'negative-list-search-history-report', category: 'Screening & Electronic Identification', title: 'Negative List Search History Report', canExecute: 'NegativeListsSearchHistoryReport' },
      { component: 'pending-verifications-report', category: 'Screening & Electronic Identification', title: 'Pending Verifications Report', canExecute: 'PendingVerificationsReport' },
      { component: 'internal-screen-notification-logs-report', category: 'Screening & Electronic Identification', title: 'Internal Screen Notification Audit Trail Report', canExecute: 'InternalScreenNotificationLogsReport' },
      //* The internal screening report needs to be below the screening list report (#80058)
      { component: 'internal-blacklist-report', category: 'Screening & Electronic Identification', title: 'Internal Black List Report', canExecute: 'InternalBlacklistReport' },
      { component: 'kyb-registry-search-history-report', category: 'KYB Registry Search History Report', title: 'KYB Registry Search History Report', canExecute: 'KompanyComSearchHistoryReport' }
    ]

    this.onboardingPortalReportsList = [
      //Onboarding Portal
      { component: 'onboarding-client-registrations-report', category: 'Onboarding Portal', title: 'Onboarding Client Registrations', canExecute: 'OnboardingClientRegistrationsReport' },
      { component: 'onboarding-client-registrations-application-progress-report', category: 'Onboarding Portal', title: 'Onboarding Client Registrations Application Progress Report', canExecute: 'OnboardingClientRegistrationsApplicationProgressReport' },
    ]

    this.otherReportsList = [
      //Other
      { component: 'eGOV-requests-report', category: 'Other', title: 'eGOV Requests Report', canExecute: 'eGOVRequestsReport' },
      { component: 'clients-gaming-account-history-report', category: 'Other', title: 'Clients Gaming Account History Report', canExecute: 'ClientsGamingAccountHistoryReport' },
      { component: 'thresholds-per-segment-report', category: 'Other', title: 'Auto Calculated Thresholds Per Segment Report', canExecute: 'GetThresholdsPerSegmentReport' },
      { component: 'thresholds-performance-per-client-report', category: 'Other', title: 'Auto Calculated Thresholds Performance Per Client', canExecute: 'GetThresholdsPerformancePerClientReport' }
    ]


    this.sortListAlphabetically(this.clientProfilingReportsList);
    this.sortListAlphabetically(this.clientEvaluationAndDocumentsReportsList);
    this.sortListAlphabetically(this.transactionMonitoringAndActionsReportsList);
    this.sortListAlphabetically(this.screeningAndElectronicIdentificationReportsList);
    this.sortListAlphabetically(this.onboardingPortalReportsList);
    this.sortListAlphabetically(this.otherReportsList);
    //*                                             ================= REPORTS =================
  }

  initiateManagement() {
    this.managementList = [
      { component: 'management', title: 'Cases', canShow: 'showManagement', canExecute: this.hasManagementCasesExecutePermissions, requiresAdmin: false },
      { component: 'cases-workflow', title: 'Cases Workflow', canShow: 'showManagement', canExecute: this.hasManagementCasesWorkflowExecutionPermissions, requiresAdmin: false },
      { component: 'rules-whitelisting', title: 'Rules Whitelisting', canShow: 'showManagement', canExecute: this.hasManagementRuleWhielistingExecutionPermissions, requiresAdmin: false },
      { component: 'segmentation', title: 'Segmentation', canShow: 'showManagement', canExecute: this.hasManagementSegmentationExecutionPermissions, requiresAdmin: false },
      { component: 'tasks', title: 'My Tasks', canShow: 'showManagement', canExecute: this.hasManagementMyTasksExecutionPermissions, requiresAdmin: false },
      { component: 'admin-tasks', title: 'Admin Tasks', canShow: 'showManagement', canExecute: this.hasManagementAdminTasksExecutePermissions, requiresAdmin: true }, // ! CHANGE TO IS ADMIN FLAG
    ]

    this.sortListAlphabetically(this.managementList);
  }

  initiatePowerBIReports() {
    this.powerBiReportsList = [];

    for (let report of this.powerBiReports) {
      this.powerBiReportsList.push({ component: report.reportId, title: report.reportName });
      // this.powerBiReportsList.push({component: 'sample-dashboard', title: 'Sample Report'});
    }

    this.sortListAlphabetically(this.powerBiReportsList);

    this.powerBiReportsListInitiated = true;
  }

  initiateProcesses() {
    //*                                             ================= REPORTS =================
    this.clientProfilingProcessesList = [
      // Client Profiling
      { component: 'handle-profiles', category: 'Client Profiling', title: 'Handle Profiles', canExecute: 'HandleProfiles' },
      { component: 'handle-evaluations', category: 'Client Profiling', title: 'Handle Evaluations', canExecute: 'HandleEvaluations' },
      { component: 'handle-clients-unified-scoring', category: 'Client Profiling', title: 'Handle Clients Unified Scoring', canExecute: 'HandleClientUnifiedScoring' },
      { component: 'handle-questionnaires', category: 'Client Profiling', title: 'Handle Questionnaires', canExecute: 'HandleQuestionnaires' },
      { component: 'handle-documents', category: 'Client Profiling', title: 'Handle Documents', canExecute: 'HandleQuestionnaires' },
      { component: 'handle-client-documents', category: 'Client Profiling', title: 'Handle Client Documents', canExecute: 'HandleClientDocuments' },
      { component: 'handle-client-tags', category: 'Client Profiling', title: 'Handle Client Tags', canExecute: 'HandleClientTags' },
    ]

    this.transactionMonitoringAndActionsProcessesList = [
      //Transaction Monitoring & Actions
      { component: 'handle-cases', category: 'Transaction Monitoring & Actions', title: 'Handle Cases', canExecute: 'HandleCases' },
      { component: 'handle-rules', category: 'Transaction Monitoring & Actions', title: 'Handle Rules', canExecute: 'HandleRules' },
      { component: 'handle-actions', category: 'Transaction Monitoring & Actions', title: 'Handle Actions', canExecute: 'HandleActions' }
    ]

    this.screeningAndElectronicIdentificationProcessesList = [
      //Screening & Electronic Identification
      { component: 'handle-screening', category: 'Screening & Electronic Identification', title: 'Handle Screening Process', canExecute: 'HandleScreeningProcesses' },
      { component: 'handle-auto-ongoing-monitoring-clients', category: 'Screening & Electronic Identification', title: 'Handle Auto Ongoing Monitoring Clients', canExecute: 'HandleAutoOngoingMonitoringClients' },
      { component: 'handle-transaction-screening-cases', category: 'Screening & Electronic Identification', title: 'Handle Transaction Screening Cases', canExecute: 'HandleTransactionScreeningCases' },
      { component: 'handle-electronic-identifications', category: 'Screening & Electronic Identification', title: 'Handle Electronic Identification Clients', canExecute: 'HandleElectronicIdentificationClients' },
      //* The internal screening report needs to be below the screening list report (#80058)
      { component: 'handle-client-worldcheck-references', category: 'Screening & Electronic Identification', title: 'Handle WorldCheck References', canExecute: 'HandleWorldCheckReferences' },
    ]

    // this.onboardingPortalProcessesList = [
    //   //Onboarding Portal
    //   { component: 'handle-onboarding-client-registrations', category: 'Onboarding Portal', title: this.handleOnboardingRegistrationsText, canExecute: 'OnboardingClientRegistrationsReport' },
    // ]

    this.otherProcessesList = [
      //Other
      {
        component: "handle-eGOV-requests",
        category: "Other",
        title: "Handle eGOV Requests",
        canExecute: "HandleeGovRequests",
      },
      {
        component: "export-clients-xml",
        category: "Other",
        title: "Export Clients XML",
        canExecute: "ExportClientsXml",
      },
      {
        component: "handle-workflow-processes",
        category: "Other",
        title: "Handle Workflow Processes",
        canExecute: "HandleWorkflowProcesses",
      },
      {
        component: "handle-kyb-registry-failed-documents",
        category: "Other",
        title: "Handle KYB Registry Failed Document",
        canExecute: "HandleKybRegistryFailedDocument",
      },
      {
        component: "trigger-external-notifications",
        category: "Other",
        title: "Trigger External Notifications",
        canExecute: "TriggerExternalNotifications",
      },
      {
        component: "handle-translations",
        category: "Other",
        title: "Handle Translations",
        canExecute: "HandleTranslations",
      },
      {
        component: "handle-pending-fraud-detection-checks",
        category: "Other",
        title: "Handle Pending Fraud Detection Checks",
        canExecute: "HandlePendingFraudDetectionChecks",
      },
    ];


    const canShowProposedUpdates = this.authService.checkUserPermission('ProposedUpdates', 'canExecute');
    if (canShowProposedUpdates) {
      this.otherProcessesList.push({
        component: "proposed-updates",
        category: "Other",
        title: "Proposed Updates",
        canExecute: "ProposedUpdates",
      },)
    }

    this.sortListAlphabetically(this.clientProfilingProcessesList);
    this.sortListAlphabetically(this.transactionMonitoringAndActionsProcessesList);
    this.sortListAlphabetically(this.screeningAndElectronicIdentificationProcessesList);
    this.sortListAlphabetically(this.otherProcessesList);
    //*                                             ================= REPORTS =================
  }

  initiateSettings() {
    this.clientProfilingSettingsList = [
      { component: 'regulation-groups', title: 'Regulation Groups' },
      { component: 'client-statuses', title: 'Client Statuses' },
      { component: 'client-categories', title: 'Client Categories' },
      { component: 'client-categorizations', title: 'Client Categorizations' },
      { component: 'banks', title: 'Banks' },
      { component: 'addressesTypes', title: 'Addresses Types' },
      { component: 'default-addresses', title: 'Default Addresses' },
      { component: 'documents', title: 'Documents' },
      { component: 'document-categories', title: 'Document Categories' },
      { component: 'mandatory-documents-categories', title: 'Mandatory Documents Categories' },
      { component: 'custom-fields', title: 'Custom Fields' },
      { component: 'capacities', title: 'Capacities' },
      { component: 'integration-capacities', title: 'Integration Capacities' },
      { component: 'checklists', title: 'Checklists' },
      { component: 'sub-groups', title: 'Sub-Groups' },
      { component: 'tags', title: 'Tags' },
      { component: 'related-website-types', title: 'Related Website Types' },
      { component: 'fatca-setup', title: 'FATCA Setup' },
      { component: 'mid-classes', title: 'MID Classes' },
      { component: 'mid-types', title: 'MID Types' },
      { component: 'reasonsForTin', title: 'Reasons For TIN' },
      { component: 'monitoring-visit-type', title: 'Monitoring Visit Types' },
      { component: 'keywords', title: 'Keywords' }
    ];

    this.countriesSettingsList = [
      { component: 'countries', title: 'Countries' },
      { component: 'cities', title: 'Cities & Ports' },
      { component: 'country-categories', title: 'Country Categories' },
      { component: 'countryevaluationgrades', title: 'Country Evaluation Grades' }
    ];

    this.transactionMonitoringAndActionsSettingsList = [
      { component: 'actions-setup', title: 'Actions Setup' },
      { component: 'dynamic-report-engine-setups', title: 'Dynamic Report Engine Setups' },
      { component: 'rule-categories', title: 'Rule Categories' },
      { component: 'case-workflow-statuses', title: 'Case Workflow Statuses' },
      { component: 'case-statuses-risk-points', title: 'Case Statuses Risk Points' },
      { component: 'case-suspicion-levels-risk-points', title: 'Case Suspicion Levels Risk Points' },
      { component: 'transactions-setup', title: 'Transactions Setup' },
      { component: 'payment-methods', title: 'Payment Methods' },
      { component: 'currency-rates', title: 'Currency Rates' },
      { component: 'trade-types', title: 'Trade Types' },
      { component: 'investment-account-types', title: 'Invest. Account Types' },
      { component: 'application-approval-setup', title: 'Application Approval Setup' },
      { component: 'contracts-setup', title: 'Contracts Setup' },
      { component: 'gaming-setups', title: 'Gaming Setups' },
      { component: 'currency-informations', title: 'Currency Information' }
    ];

    this.evaluationAndQuestionnaireSettingsList = [
      { component: 'evaluation-grades', title: 'Evaluation Grades' },
      { component: 'criteria', title: 'Criteria' },
      { component: 'criteria-categories', title: 'Criteria Categories' },
      { component: 'evaluation-types', title: 'Adhoc Evaluation Types' },
      { component: 'questions', title: 'Questions' },
      { component: 'questions-categories', title: 'Questions Categories' },
      { component: 'questionnaire-grades', title: 'Questionnaire Grades' },
      { component: 'questionnaire-types', title: 'Questionnaire Types' }
    ];

    this.firmsSettingsList = [
      { component: 'firm-evaluation-grades', title: 'Firm Evaluation Grades' },
      { component: 'firm-criteria', title: 'Firm Criteria' },
      { component: 'firm-criteria-categories', title: 'Firm Criteria Categories' },
      { component: 'firm-mitigation-measures', title: 'Firm Mitigation Measures' }
    ];

    this.templatesAndReportSetups = [
      { component: 'intro-endings', title: 'Email Intro & Endings' },
      { component: 'email-templates', title: 'Email Templates' },
      { component: 'notification-templates', title: 'Notification Templates' },
      { component: 'report-engine-setups', title: 'Report Engine Setup' },
    ];

    this.sortListAlphabetically(this.clientProfilingSettingsList);
    this.sortListAlphabetically(this.countriesSettingsList);
    this.sortListAlphabetically(this.transactionMonitoringAndActionsSettingsList);
    this.sortListAlphabetically(this.evaluationAndQuestionnaireSettingsList);
    this.sortListAlphabetically(this.templatesAndReportSetups);
    this.sortListAlphabetically(this.firmsSettingsList);
  }

  startTour() {
    var customs = { prev: 'prev', next: 'next', done: 'done' }

    const tour = this.joyrideService.startTour(
      {
        steps:
          [
            'tour-start',
            'tour-home-page',
            'tour-know-your-clients',
            'tour-know-your-transactions',
            'tour-processes',
            'tour-management',
            'tour-reports',
            'tour-settings',
            'tour-administration'
          ],
        themeColor: '#5a5558',
        stepDefaultPosition: 'right',

      }
    ).subscribe((data: JoyrideStepInfo) => {

      // number = 0 -> First step from above
      if (data.number == 0) {
        // When we start the tour, the screen is automatically scrolled on the bottom,
        // hence we need to scroll the screen on the top` again 
        window.scroll(0, 0);
        //tour.unsubscribe();
      }
    }, error => {
      tour.unsubscribe();
    });
  }

  updateUserFirstLoginFlag() {
    // update User's HasFirstLogin flag
    forkJoin({
      updateUserRequest: this.apiService.updateUsersHasFirstLoginFlag()
    }).subscribe(data => {
      this.hasFirstLogin = true;
    }, error => {
      this.helperFunctionsService.displayNotificationErrorAlert(error);
    });
  }

  onSearchInputChange(event) {
    this.searchTerm = event.target.value;
  }

  cleanSearchInput(searchInput) {
    this.searchTerm = null;
    searchInput.value = null;
  }

  sortListAlphabetically(list: any) {
    list.sort(function (a, b) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
      if (a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
      return 0;
    });
  }

}
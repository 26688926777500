<a class="sa-button {{color}} {{alignment}} {{colorShade}} {{size}} {{customClass}}" id="{{id}}"
  [routerLink]="routerLink ? routerLink : []" (click)="onClick.emit()" title="{{hint}}" type="{{buttonType}}"
  [ngClass]="{'with-icon': icon !== null, 'disabled': this.isDisabled === true}" placement="bottom" container="body"
  triggers="mouseenter:mouseleave" [popoverTitle]="popoverTitle|dynamicPopover:'title'"
  [popover]="popoverBody|dynamicPopover:'body'" [ngStyle]="customStyles">
  <div class="icon" *ngIf="icon !== null">
    <i style="font-size: 1.2em;" class="las la-{{icon}}"></i>
  </div>
  <div class="text">
    <ng-content></ng-content>
  </div>
</a>
import { Injectable } from '@angular/core';

import { AuthService } from 'app/core/services/auth.service';
import { JsonApiService } from "@app/core/services/json-api.service";
import { Subject } from 'rxjs';
import * as englishJson from "../../../assets/api/langs/us.json";

@Injectable()
export class I18nService {

  public state;
  public data: {};
  public currentLanguage: any;
  public jsonData: any;


  constructor(private jsonApiService: JsonApiService, public authService: AuthService) {
    this.state = new Subject();
    this.initLanguage();
  }

  private initLanguage() {
    if (this.currentLanguage == undefined) {
      this.currentLanguage = this.authService.user ? this.authService.user.languageObj : null;

      if (this.currentLanguage == undefined)
        this.fetch('us');
      else
        this.fetch(this.currentLanguage.key);
    } else if (this.authService.user && this.currentLanguage != this.authService.user.languageObj) {
      this.currentLanguage = this.authService.user ? this.authService.user.languageObj : null;

      if (this.currentLanguage == undefined)
        this.fetch('us');
      else
        this.fetch(this.currentLanguage.key);
    }

    this.jsonData = englishJson;
    // console.log(this.jsonData);
  }


  private fetch(locale: any) {
    this.jsonApiService.fetch(`/langs/${locale}.json`)
      .subscribe((data: any) => {
        this.data = data;
        this.state.next(data);
        // this.ref.tick()
      })
  }

  /* private initLanguage(locale: string) {
     let language = languages.find((it) => {
       return it.key == locale
     });
     if (language) {
       this.currentLanguage = language
     } else {
       throw new Error(`Incorrect locale used for I18nService: ${locale}`);
 
     }
   }*/

  setLanguage(language) {
    if (language) {
      this.currentLanguage = language;
      this.fetch(language.key)
    }
  }


  subscribe(sub: any, err: any) {
    return this.state.subscribe(sub, err)
  }

  public getTranslation(phrase: string): string {
    if (this.authService.user && this.currentLanguage != this.authService.user.languageObj) {
      this.currentLanguage = this.authService.user ? this.authService.user.languageObj : null;

      if (this.currentLanguage == undefined)
        this.fetch('us');
      else
        this.fetch(this.currentLanguage.key);
    }


    return this.data && this.data[phrase] ? this.data[phrase] : phrase
  }
}

export function i18nFactory(service: I18nService): Function {
  return () => service;
}
import {Injectable, EventEmitter} from "@angular/core";
import {BehaviorSubject} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class DataService {

    parentToChildBridge = new EventEmitter<any>();
    childToParentBridge = new EventEmitter<any>();
    wizardStepsComponentRefreshBridge = new EventEmitter<any>();
    componentBridge = new EventEmitter<any>();

    private clientInfoSubject = new BehaviorSubject<any>({});
    clientInfo$ = this.clientInfoSubject.asObservable();

    private routeData$ = new BehaviorSubject(null);

    get routeDataObservable() {
        return this.routeData$.asObservable();
    }

    set routeData(value) {
        this.routeData$.next(value);
    }

    updateClientInfo(newInfo: any) {
        this.clientInfoSubject.next(newInfo);
    }

    setRouteData(value) {
        this.routeData = value;
    }

    constructor() {
    }
}

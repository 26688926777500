import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToYesNo',
})
export class BoolToYesNoPipe implements PipeTransform {

  transform(theBool: boolean): string {
    return theBool == true ? 'Yes' : 'No';
  }
}


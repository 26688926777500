import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Directive({
    selector: '[numberCounter]'
})
export class NumberCounterDirective implements OnInit {

    @Input() start: number = 0;
    @Input() end: number = 0;
    @Input() duration: number = 1000;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.innerText = this.start.toString();
        this.animateCounter();
    }

    animateCounter() {
        const countingAnimation = trigger('counting', [
            state('start', style({
                opacity: 1,
                transform: 'scale(1)'
            })),
            state('end', style({
                opacity: 1,
                transform: 'scale(1.5)'
            })),
            transition('start => end', [
                animate(`${this.duration}ms ease-out`)
            ])
        ]);

        const animationState = this.el.nativeElement.animate(countingAnimation, {
            duration: this.duration,
            fill: 'forwards'
        });

        animationState.onfinish = () => {
            this.el.nativeElement.innerText = this.end.toString();
        }
    }
}
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-screening-results-provider',
  templateUrl: './screening-results-provider.component.html'
})
export class ScreeningResultsProviderComponent {

  @Input() serviceName: string = '';
}

<a *ngIf="routerLink" [routerLink]="routerLink" routerLinkActive="active" joyrideStep="{{menuTour}}"
  text="{{tourDescription}}"
  [ngStyle]="{'padding-left': (subMenuLevel > 1 ? 40 + (subMenuLevel * 10) : 50) + 'px', 'color': itemColor}"
  [ngClass]="{'active': (hasSubMenu && expanded && !minimize), 'has-sub-menu': hasSubMenu}"
  (click)="onClickFn(); expandMenuItem();">
  <i class="las la-{{icon}} item" *ngIf="!subMenu"></i>
  <span *ngIf="showContent" @fade>{{title}}</span>
  <i class="expand las la-plus" *ngIf="hasSubMenu && !expanded && showContent" @fade></i>
  <i class="expand las la-minus" *ngIf="hasSubMenu && expanded && showContent" @fade></i>
</a>
<a *ngIf="!routerLink" routerLinkActive="active" joyrideStep="{{menuTour}}" text="{{tourDescription}}"
  [ngStyle]="{'padding-left': (subMenuLevel > 1 ? 40 + (subMenuLevel * 10) : 50) + 'px', 'color': itemColor}"
  [ngClass]="{'active': (hasSubMenu && expanded && !minimize), 'has-sub-menu': hasSubMenu}"
  (click)="onClickFn(); expandMenuItem();">
  <i class="las la-{{icon}} item" *ngIf="!subMenu"></i>
  <span *ngIf="showContent" @fade>{{title}}</span>
  <i class="expand las la-plus" *ngIf="hasSubMenu && !expanded && showContent" @fade></i>
  <i class="expand las la-minus" *ngIf="hasSubMenu && expanded && showContent" @fade></i>
</a>
<div *ngIf="hasSubMenu && expanded && !minimize">
  <ng-content></ng-content>
</div>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SaMenuItemComponent} from "./sa-menu-item.component";
import { JoyrideModule } from 'ngx-joyride';

// const UI = [
// ];

@NgModule({
  declarations: [
    SaMenuItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    JoyrideModule.forRoot(),
    // ...UI
  ],
  exports: [
    SaMenuItemComponent
  ]
})
export class SaMenuItemModule {
}

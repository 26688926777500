import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthTokenService } from './auth-token.service';
import { StorageService } from './storage.service';
import * as fromAuth from '../store/auth';

@Injectable()
export class AuthService {
  private user$ = new BehaviorSubject(null);
  public isAdmin$ = new BehaviorSubject(null);

  isLoggedIn: boolean = false;
  userClaims: any;
  userReports: any;
  userModules: any;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private httpClient: HttpClient
    , public authToken: AuthTokenService
    , private apiService: ApiService
    , private storage: StorageService
    , private store: Store<fromAuth.AuthState>

  ) { }


  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          if (user) {
            this.isLoggedIn = true;
            this.user = user;
            this.userClaims = user.claims;
            resolve(user);
          }
          resolve(null);
        },
        error => {
          resolve(null);
        }
      )
    })
  }

  set user(value) {
    this.user$.next(value);
  }
  get user() {
    return this.user$.value;
  }

  set isAdmin(value) {
    this.isAdmin$.next(value);
  }
  get isAdmin() {
    return this.isAdmin$.value;
  }

  get userObservable() {
    return this.user$.asObservable();
  }

  loginWindowsAuthentication(): any {
    return this.httpClient.get(environment.URL + "/windowsAuthorization/checkAuthentication").pipe(
      map((data: any) => {
        this.isLoggedIn = true;
        this.loadUser();
        return true;
      },
        catchError(this.handleError)),
    );
  }

  login(username: string, password: string): any {
    var grant_type = "password";
    var scope = "offline_access";

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    let body = new URLSearchParams();
    body.set('grant_type', grant_type);
    body.set('username', username);
    body.set('password', password);
    body.set('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    return this.httpClient.post(environment.LOGINURL, body.toString(), httpOptions)
      .pipe(
        tap((res: any) => {
          this.store.next(new fromAuth.LoggedOnce(true));
          this.store.next(new fromAuth.AuthTokenPayload(res.access_token));
          this.store.next(new fromAuth.LoginAction('/'));
        }),
        map((data: any) => {
          this.isLoggedIn = true;
          this.authToken.token = data.access_token;
          this.loadUser();
          return true;
        },
          catchError(this.handleError))
      );
  }

  otpLogin(username: string, password: string): any {
    var grant_type = "password";
    var scope = "offline_access";

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let body = new URLSearchParams();
    body.set('grant_type', grant_type);
    body.set('username', username);
    body.set('password', password);
    // body.set('scope', scope);
    body.set('timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    return this.httpClient.post(environment.LOGINURL, body.toString(), httpOptions)
      .pipe(
        map((data: any) => {
          this.store.next(new fromAuth.LoggedOnce(true));
          this.store.next(new fromAuth.AuthTokenPayload(data.access_token));
          this.store.next(new fromAuth.LoginAction('/'));

          return data.access_token;
        },
          catchError(this.handleError))
      );
  }

  confirmLogin(): any {
    return new Promise<any>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      if (this.userClaims) {
        var loginTimestamp = this.userClaims.find(
          (x) => x.type == "LoginTimestamp"
        );
        if (loginTimestamp) {
          this.httpClient
            .post(
              environment.URL +
              `/user/confirmLogin/${this.user.username}/${loginTimestamp.value}`,
              null,
              httpOptions
            )
            .subscribe((data) => {
              resolve(data);
            });
        }
      }
    });
  }

  loadUser() {
    return new Promise<void>((resolve, reject) => {

      const getUser = this.apiService.getUser().subscribe((data) => {
        //! ** Do not change the order of line codes **
        // We are calling the doCheckUserPermission() method in the navigation.component.ts, once the user is being initialized
        // Hence, the remaining attributes will be undefined, and the doCheckUserPermission() method will be not executed appropriately.

        this.userClaims = data.claims;
        this.userReports = data.reports;
        this.userModules = data.modules;
        this.user = data;
        this.user.admin = false;
        //this.storage.set("ISADMIN", false);
        if (this.user.roles.some(x => x === "SystemAdmin")) {
          this.user.admin = true;
          this.user.isRoleAdmin = true;
          //this.storage.set("ISADMIN", true);
        }
        this.storage.set("USER", this.user).then(_ => {
          this.IsAdmin().then(
            () => {
              resolve();
            }
          );
        });

        getUser.unsubscribe();
      }, error => getUser.unsubscribe());
    });
  }

  public IsAdmin() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          if (user == null) {
            this.isAdmin = false;
          }
          else {
            this.isAdmin = user.admin;
          }
          resolve(this.isAdmin);
        },
        error => {
          reject("Error in Helper.IsAdmin");
        }
      )
    });
  }

  public GetUsername() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          resolve(user.username);
        },
        error => {
          reject("Error in Helper.GetUsername");
        }
      )
    });
  }

  public GetUserEmail() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          resolve(user.email);
        },
        error => {
          reject("Error in Helper.GetUserEmail");
        }
      )
    });
  }

  logout(redirectToLogin: boolean = true): void {
    let _self = this;
    this.storage.remove("USER")
      .then(() => {
        //Also clear session storage
        this.storage.clearSessionStorage().then(_ => {
          this.store.next(new fromAuth.LogoutAction(redirectToLogin));
          _self.storage.remove
          _self.authToken.token = null;
          _self.isLoggedIn = false;
        });
      });

    localStorage.clear();
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.error.error_description
      ? error.error.error_description
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
    //return Observable.throw(errMsg);
  }

  public checkUserPermission(permissionType: string, permissionToCheck: string) {
    var isFound = this.userClaims ? this.userClaims.some(x => x.type == permissionType && x[permissionToCheck] == true) : false;
    return isFound;
  }

  public checkCanShowReport(permissionType: string, permissionToCheck: string) {
    var isFound = this.userClaims ? this.userClaims.some(x => x.type == permissionType && x[permissionToCheck] == true) : false;
    return isFound != true ? false : true;
  }

  public checkCanShowModule(permissionType: string, permissionToCheck: string) {
    var isFound = this.userClaims ? this.userClaims.some(x => x.type == permissionType && x[permissionToCheck] == true) : false;
    return isFound != true ? false : true;
  }

  // This function is called to synchronize the user stored in local storage with the
  // user variable. For example, the timezone is updated in the user variable, so it will also be 
  // updated in the local storage
  public syncUser() {
    this.storage.set("USER", this.user);
  }
}

export function AuthFactory(service: AuthService): Function {
  return () => service.load();
}
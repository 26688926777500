import { I } from "@angular/cdk/keycodes";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router"; 
import { RouteStructure } from "@app/shared/classes/routeStructure";
import { Observable } from "rxjs";
import { HelperFunctionsService } from "../services";
import { AuthService } from "../services/auth.service";

@Injectable()
export class BlockConcurrentLoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private helper: HelperFunctionsService
  ) {}

  public routeStructure = new RouteStructure();

  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.auth.confirmLogin().then((data) => {
      if (!data) {
        this.routeStructure.savePreviousPage(this.router);
        this.router.navigate(["auth/login"]);
        this.helper.displayNotificationErrorAlertCustomText(
          "Invalid Login",
          "Your account was logged in from a different browser/computer. Please sign-in again to continue",
          ""
        );
        return false;
      }
      return true;
    });
    return true;
  }
}

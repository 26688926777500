import { Component } from '@angular/core';
import { AuthService } from 'app/core/services';

@Component({
  selector: 'sa-user-options',
  templateUrl: './user-options.component.html',
})
export class UserOptionsComponent  {
  constructor(
    public authService: AuthService) {}
}

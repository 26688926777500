import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'sa-icon-button',
  templateUrl: './sa-icon-button.component.html',
  styleUrls: ['./sa-icon-button.component.scss']
})
export class SaIconButtonComponent {
  @Input() title: string = "";
  @Input() routerLink: string = ""; // Button router link
  @Input() icon: string = 'external-link-alt'; // Button icon
  @Input() colorShade: string = 'dark'; // Options: dark, light
  @Input() color: string = "#838383"; // Color of icon
  @Input() size: string = 'md'; // Options: lg, md, sm
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>(); // Click event of the icon
}

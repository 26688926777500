<div style="position: relative;">
  <sa-icon-button title="Notifications" (onClick)="onToggle()" icon="bell" size="lg"></sa-icon-button>
  <b *ngIf="count > 0" id="activity-badge" class="badge bg-color-red">{{count}}</b>
</div>


<div id="notifications-dropdown" style="height: 450px;" class="ajax-dropdown" #dropdown>
  <div *ngIf="loading" class="c-loading-overlay">
    <div class="custom-loader-wrapper">
      <div class="custom-loader"></div>
    </div>
  </div>
  <div class="nt-head">
    <div class="nt-box" *ngFor="let activity of activities">
      {{getActivityTitle(activity)}}
    </div>
    <div class="mark-read">
      <span (click)="markAllAsRead()">
        <span class="c-check c-check-1">✓</span>
        <span class="c-check c-check-2">✓</span>
        {{'Mark all as read'|i18n}}</span>
    </div>
  </div>

  <!-- notification content -->
  <div class="ajax-notifications custom-scroll">
    <ul class="notification-body" *ngIf="currentActivity">
      <ng-template ngFor let-item [ngForOf]="currentActivity.data">
        <li activitiesNotification *ngIf="item.type == 'notification'" [item]="item" (updateEvent)="update()"></li>
      </ng-template>
    </ul>
  </div>
  <!-- end notification content -->

  <!-- footer: refresh area -->
  <div (click)="update()" title="Refresh notifications" class="refresh-nt">
    <div class="last-update-wrapper">
      <span class="last-update-text"><i style="margin-right: 3px;font-size: 1.1em;" class="fa fa-clock-o"
          aria-hidden="true"></i>
        {{ 'Last Update:' | i18n }} {{lastUpdate | moment:"h:mm A"}}</span>
      <div (click)="update()" title="Refresh notifications" class="refresh-nt">
        <i class="fa fa-refresh" *ngIf="!loading"></i>
        <i style="margin-right: 7px;font-size: .9em;" class="fa fa-refresh fa-spin" *ngIf="loading"></i>
        <span *ngIf="loading">{{ 'Loading' | i18n}}...</span>
      </div>
    </div>
    <!-- end footer -->
  </div>
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, NotificationService } from "app/core/services";

@Component({
  selector: "sa-logout",
  templateUrl: './logout.component.html',
  styles: []
})
export class LogoutComponent implements OnInit {

  public user

  constructor(
    public authService: AuthService,
    private router: Router,
    private notificationService: NotificationService
  ) {
  }

  showPopup() {
    this.notificationService.smartMessageBox(
      {
        title:
          "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout <span class='txt-color-orangeDark'><strong>" + this.authService.user.username + "</strong></span> ?",
        content:
          "You can improve your security further after logging out by closing this opened browser",
        buttons: "[No][Yes]"
      },
      ButtonPressed => {
        if (ButtonPressed == "Yes") {
          this.logout();
        }
      }
    );
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/auth/login"]);
  }

  ngOnInit() {

  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { BlockConcurrentLoginGuard } from './core/guards/block-concurrent-login.guard';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "auth",
        pathMatch: "full"
      },
      {
        path: "auth",
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
        data: { pageTitle: "Auth" }
      }
    ]
  },
  {
    path: 'main',
    component: MainLayoutComponent,
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    loadChildren: () => import('./features/main/main.module').then(m => m.MainModule),
    data: { pageTitle: "Home" }
  },
  {
    path: 'management',
    component: MainLayoutComponent,
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    loadChildren: () => import('./features/management/management.module').then(m => m.ManagementModule),
    data: { pageTitle: "Management" }
  },
  {
    path: 'my-tasks',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/tasks/tasks.module').then(m => m.MyTasksModule),
    data: { pageTitle: "My Tasks" }
  },
  {
    path: 'know-your-transactions',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/know-your-transactions/know-your-transactions-root.module').then(m => m.KnowYourTransactionsRootModule),
    data: { pageTitle: "Know Your Transactions" }
  },
  {
    path: 'know-your-firms',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/know-your-firms/know-your-firms.module').then(m => m.KnowYourFirmsModule),
    data: { pageTitle: "Know Your Firms" }
  },
  {
    path: 'processes',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/processes-tab/processes-tab.module').then(m => m.ProcessesTabModule),
    data: { pageTitle: "Processes" }
  },
  {
    path: 'reports',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule),
    data: { pageTitle: "Reports" }
  },
  {
    path: 'settings',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
    data: { pageTitle: "Settings" }
  },
  {
    path: 'administration',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/administration/administration.module').then(m => m.AdministrationModule),
    data: { pageTitle: "Administration" }
  },
  {
    path: 'system-settings',
    canActivate: [BlockConcurrentLoginGuard, AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () => import('./features/system-settings/system-settings.module').then(m => m.SystemSettingsModule),
    data: { pageTitle: "System Settings" }
  },
  { path: '**', redirectTo: 'miscellaneous/error404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [BlockConcurrentLoginGuard, AuthGuard]
})
export class AppRoutingModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BoolToYesNoPipe } from './bool-to-yes-no.pipe';
import { CamelToTextPipe } from './camel-to-text.pipe';
import { CustomDatasourcePipe } from './custom-datasource.pipe';
import { DynamicPopoverPipe } from './dynamic-popover.pipe';
import { FieldFilterPipe } from './field-filter.pipe';
import { FieldSortPipe } from './field-sort.pipe';
import { MomentPipe } from './moment.pipe';
import { SafePipe } from './safe.pipe';
import { ObjectToArrayPipe } from './objectToArray.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe,
    MomentPipe,
    SafePipe,
    CamelToTextPipe,
    FieldSortPipe,
    BoolToYesNoPipe,
    DynamicPopoverPipe,
    CustomDatasourcePipe,
    ObjectToArrayPipe],
  exports: [FieldFilterPipe,
    MomentPipe,
    SafePipe,
    CamelToTextPipe,
    FieldSortPipe,
    BoolToYesNoPipe,
    DynamicPopoverPipe,
    CustomDatasourcePipe,
    ObjectToArrayPipe]
})
export class PipesModule { }

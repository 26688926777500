import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";

import {RouterModule} from "@angular/router";
import {DxSelectBoxModule} from "devextreme-angular";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {I18nModule} from '../i18n/i18n.module';
import {UtilsModule} from "../utils/utils.module";
import {AuthLayoutComponent} from './app-layouts/auth-layout.component';
import {EmptyLayoutComponent} from './app-layouts/empty-layout.component';
import {MainLayoutComponent} from './app-layouts/main-layout.component';
import {FooterComponent} from "./footer/footer.component";
import {HeaderModule} from "./header/header.module";
import {LayoutSwitcherComponent} from "./layout-switcher.component";
import {NavigationModule} from "./navigation/navigation.module";
import {RibbonComponent} from "./ribbon/ribbon.component";
import {RouteBreadcrumbsComponent} from './ribbon/route-breadcrumbs.component';
import {ShortcutComponent} from "./shortcut/shortcut.component";
import {SaHeaderRightButtonsModule} from "app/templates/sa-header-right-buttons/sa-header-right-buttons.module";

const UI = [
    SaHeaderRightButtonsModule
];

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        NavigationModule,
        FormsModule,
        RouterModule,
        UtilsModule,
        TooltipModule,
        BsDropdownModule,
        I18nModule,
        DxSelectBoxModule,
        ...UI
    ],
    declarations: [
        FooterComponent,
        RibbonComponent,
        ShortcutComponent,
        LayoutSwitcherComponent,
        MainLayoutComponent,
        EmptyLayoutComponent,
        AuthLayoutComponent,
        RouteBreadcrumbsComponent,
    ],
    exports: [
        HeaderModule,
        NavigationModule,
        FooterComponent,
        RibbonComponent,
        ShortcutComponent,
        LayoutSwitcherComponent,
        DxSelectBoxModule
    ]
})
export class SmartadminLayoutModule {

}

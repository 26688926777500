import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

import { IonicStorageModule } from "@ionic/storage";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../../environments/environment";
import { AppEffects } from "./app.effects";
import { AuthGuard } from "./guards/auth.guard";
import * as fromStore from "./store";

import { AuthFactory, AuthService, AuthTokenFactory, AuthTokenService, TokenInterceptor, services } from 'app/core/services';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    // HotkeysModule.forRoot(),

    StoreModule.forRoot(fromStore.reducers, {
      metaReducers: fromStore.metaReducers
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([...fromStore.effects, AppEffects])
  ],
  exports: [],
  providers: [
    AuthGuard,

    ...services,
    ...fromStore.services,

    {
      provide: APP_INITIALIZER,
      useFactory: AuthTokenFactory,
      deps: [AuthTokenService],
      multi: true
    },

    {
      provide: APP_INITIALIZER,
      useFactory: AuthFactory,
      deps: [AuthService],
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },   

  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

}

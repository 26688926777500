<footer class="custom-fixed-footer mt-5">
  <div class="footer-content">
    <span *ngIf="displayCopyrights == null ? false : displayCopyrights" class="copyright">
      Copyright © {{solutionName}} {{appVersion}}, All rights Reserved
    </span>
    <span *ngIf="developedBy" class="developer">
      Developed by {{developedBy}}
    </span>
    <span *ngIf="isSystemAdmin" class="db-info">
      Connected with Database: {{dbName}}
    </span>

      <div class="time-zone" title="Time Zone" [hidden]="timeZones.length == 0">
        <i class="fa fa-globe" aria-hidden="true"></i>
        <dx-select-box *ngIf="authService.user" (onItemClick)="onUserTimezoneChange($event)" [height]="33"
          [dataSource]="timeZones" [(value)]="authService.user['timezone']" [showClearButton]="false" placeholder=""
          displayExpr="timeZone" valueExpr="id" searchMode="contains" searchExpr="timeZone" [minSearchLength]="0"
          [searchEnabled]="true" [showDataBeforeSearch]="true">
        </dx-select-box>
      </div>
  </div>
</footer>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToText',
})
export class CamelToTextPipe implements PipeTransform {

  transform(text: string): string {
    return text ? text.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase()) : '';
  }
}


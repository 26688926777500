import { ActivatedRoute, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";

/**
 * Documentation on how to use
 * Typescript under export: Add "public routeStructure = new RouteStructure();"
 * Typescript in contructor: Add "private route: ActivatedRoute"
 * To set the client values from route call in contructor: "this.routeStructure.updateClientValuesFromRoute(route);" This is used mostly in client details
 * To get route from the client values in routing call: "let route = this.routeStructure.getClientStructureRouteFromActivatedRoute("personSearchProfile/" + e.row.data["goToProfile"])"
 * To get route from values manually call: "let route = this.routeStructure.getClientStructureRouteFromValues(rootClientId, rootClientType, childClientId, childClientType, "personSearchProfile/" + e.row.data["goToProfile"])"
 * Go to routing: "this.router.navigate([route]);"
 */

@Injectable({
  // we declare that this service should be created
  // by the root application injector.
  providedIn: "root",
})
export class RouteStructure {
  rootClientId: number;
  rootClientType: number;
  childClientId: number;
  childClientType: number;

  constructor(route?: ActivatedRoute) {
    if (route != null)
      route.parent.params.subscribe((data) => {
        this.rootClientId = data["rootClientId"];
        this.rootClientType = data["rootClientType"];
        this.childClientId = data["id"];
        this.childClientType = data["clientType"];
      });
  }

  public updateClientValuesFromRoute(route: ActivatedRoute) {
    route.parent.params.subscribe((data) => {
      this.rootClientId = data["rootClientId"];
      this.rootClientType = data["rootClientType"];
      this.childClientId = data["id"];
      this.childClientType = data["clientType"];
    });
  }

  public setValues(
    rootClientId: number,
    rootClientType: number,
    childClientId: number,
    childClientType: number
  ) {
    this.rootClientId = rootClientId;
    this.rootClientType = rootClientType;
    this.childClientId = childClientId;
    this.childClientType = childClientType;
  }

  public getClientStructureRouteFromValues(
    rootClientId: number,
    rootClientType: number,
    childClientId: number,
    childClientType: number,
    path: string
  ) {
    return this.getClientStructureRoute(
      rootClientId,
      rootClientType,
      childClientId,
      childClientType,
      path
    );
  }

  public getClientStructureRouteFromActivatedRoute(path: string) {
    return this.getClientStructureRoute(
      this.rootClientId,
      this.rootClientType,
      this.childClientId,
      this.childClientType,
      path
    );
  }

  private getClientStructureRoute(
    rootClientId: number,
    rootClientType: number,
    childClientId: number,
    childClientType: number,
    path: string
  ) {
    let route = "";
    /**
     * if a root client individual wants to go to client corporate or joint
     * we need to set the root as the child to design the structure
     */
    if (rootClientType == 1 && childClientType != undefined) {
      rootClientType = childClientType;
      rootClientId = childClientId;
    }

    let clientTypeRouteText = "";

    if (rootClientId == undefined || rootClientType == undefined) {
      //when we do not need client structure
      if (childClientType == 1) {
        clientTypeRouteText = "client-individual";
      } else if (childClientType == 2) {
        clientTypeRouteText = "client-corporate";
      } else if (childClientType == 3) {
        clientTypeRouteText = "client-joint";
      }
      route = `/main/${clientTypeRouteText}/${childClientId}/${childClientType}`;
    } else if (rootClientType == 1) {
      //when the root is client individual
      if (rootClientType == 1) {
        clientTypeRouteText = "client-individual";
      } else if (rootClientType == 2) {
        clientTypeRouteText = "client-corporate";
      } else if (rootClientType == 3) {
        clientTypeRouteText = "client-joint";
      }
      route = `/main/${clientTypeRouteText}/${rootClientId}/${rootClientType}`;
    } else if (rootClientType == 2 || rootClientType == 3) {
      //when we have client structure
      if (childClientType == 1) {
        clientTypeRouteText = "client-individual";
      } else if (childClientType == 2) {
        clientTypeRouteText = "client-corporate";
      } else if (childClientType == 3) {
        clientTypeRouteText = "client-joint";
      }
      route = `/main/client-structure/${rootClientId}/${rootClientType}/${clientTypeRouteText}/${childClientId}/${childClientType}`;
    }

    if (path == undefined) {
      path = "";
    }

    return `${route}/${path}`;
  }

  savePreviousPage(router) {
    let href = router.url;
    let dateTime = new Date();

    if (href != "auth/login" && href != "/auth/login") {
      localStorage.setItem("previousURL", href);
      localStorage.setItem("previousURLdate", dateTime.toLocaleString());
      // console.log("Previous Page: " + href);
    }
  }

  getPreviousPage() {
    try {
      if (
        localStorage.getItem("previousURL") &&
        localStorage.getItem("previousURLdate")
      ) {
        let previousURL = localStorage.getItem("previousURL");
        let previousURLdateStr = localStorage.getItem("previousURLdate");
        let dateTime: any = new Date();
        let previousURLdate: any = new Date(previousURLdateStr);

        var diff = Math.abs(dateTime - previousURLdate);
        var minutes = Math.floor(diff / 1000 / 60);

        if (minutes <= 5) {
          localStorage.removeItem("previousURL");
          localStorage.removeItem("previousURLdate");

          return previousURL;
        } else {
          return "/main";
        }
      } else {
        return "/main";
      }
    } catch {
      return "/main";
    }
  }

  getClientId(){
    return this.childClientId;
  }

  getClientType(){
    return this.childClientType;
  }
}

import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, HelperFunctionsService } from 'app/core/services';

declare var $: any;

@Component({
  selector: 'sa-health-check',
  templateUrl: './health-check.component.html',
})
export class HealthCheckComponent implements OnInit, OnDestroy {
  active: boolean;
  loading: boolean;
  healthCheck: any;
  lastRefresh: any;

  private documentSub: any;

  constructor(
    private el: ElementRef,
    private helperFunctionsService: HelperFunctionsService,
    private renderer: Renderer2,
    private apiService: ApiService,
    private router: Router
  ) {
    this.active = false;
    this.loading = false;
    this.lastRefresh = new Date();
  }

  ngOnInit() {
    this.helperFunctionsService.refreshHealthCheckIndicator$.subscribe(_ => this.update());
  }

  navigateToHealthCheckAnalysis() {
    this.onToggle();
    this.router.navigate(['/main/health-check-dashboard']);
  }

  onToggle() {
    let dropdown = $('.ajax-dropdown', this.el.nativeElement);
    this.active = !this.active;
    if (this.active) {
      dropdown.fadeIn()
      this.documentSub = this.renderer.listen('document', 'mouseup', (event) => {
        if (!this.el.nativeElement.contains(event.target)) {
          dropdown.fadeOut();
          this.active = false;
          this.documentUnsub()
        }
      });
    } else {
      dropdown.fadeOut()
      this.documentUnsub()
    }
  }

  update() {
    this.loading = true;
    const getHealthCheckIndicator = this.apiService.getHealthCheckIndicator()
      .subscribe(data => {
        this.healthCheck = data;
        this.lastRefresh = new Date();
        this.loading = false;

        getHealthCheckIndicator.unsubscribe();
      }, () => getHealthCheckIndicator.unsubscribe());
  }

  ngOnDestroy() {
    this.documentUnsub()
  }

  documentUnsub() {
    this.documentSub && this.documentSub();
    this.documentSub = null
  }
}

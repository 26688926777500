import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SaIconButtonComponent} from "./sa-icon-button.component";

// const UI = [
// ];

@NgModule({
  declarations: [
    SaIconButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    // ...UI
  ],
  exports: [
    SaIconButtonComponent
  ]
})
export class SaIconButtonModule {
}
